import React, { Component, Fragment } from "react";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { Global } from "../../helpers/global";
import "../../css/calendar.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";

const url = 'events';

export default class FullCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: process.env.REACT_APP_API_HOST + url,
      calendar: null,
      id: 0,
      events: {},      
      dialogAddEditOpen: false,
      eventContactName: '',
      schema: [],
      isDataLoading: false,
      isDialogDataLoading: false,
      isLoading: true,
      order: '',
      orderBy: '',
      rowsPerPage: '',
      page: '',
      searchFor: '',
      selectedUserId: 0,
      snackBarMsg: '',
      snackBarMsgType: '',
      snackBarOpen: false,
    };
    this.calendarRef = React.createRef();
  }
  
  handleNewEventChange = (name) => event => {
    Global.setStateValue(this, name, event.target.value);
  };
  handleChangeDate = (name, date) => {
    Global.setStateValue(this, name, date);
  };
  
  componentDidMount() {        
    var events = [];
    if (this.props.Events.length>0) {
        this.props.Events.forEach((row) => {
            events.push(row);
        })
    }
    const calendar = new Calendar(this.calendarRef.current, {      
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],

      defaultView: "timeGridDay",
      header: {
        center: "title",
        right: "addEventButton today prev,next",
        left: "timeGridDay, dayGridMonth, timeGridWeek"
      },
      timezone: "EST",

      //selection
      selectable: true,
      select: this.select,
      timeFormat: 'HH:mm',
      interval: 30,
      minTime: '07:00',
      maxTime: '20:00',
      startTime: '07:00',
      timeType: "string",
      dynamic: true,
      dropdown: true,
      scrollbar: true,

      //eventDataTransform: this.convertEvent,
      customButtons: {
        addEventButton: {
          text: 'add event',
          click: this.props.parentComponent.addEvent,
        }
      },
      events: events,
      eventClick: this.eventClick,
      
    });

    calendar.render();
    this.setState({ calendar });

  }

  setComponentState = (sState, vState) => {
    this.setState({ [sState]: vState });
  }
  eventClick = info => {
    //this is the new implementation:
    // console.log('view',this.state.calendar.view);
    this.props.parentComponent.editEvent(info);

    // eslint-disable-next-line
    const start = this.state.calendar.getOption("visibleRange");
    // eslint-disable-next-line
    const vEvents = this.state.calendar.getEvents().filter(event => {
      return true;
    });
    //  console.log('vevents',vEvents,start);
    // info.event.setProp("color", "darkgreen");
  };

  render() {    
    return (
      <Fragment>
       
          <div ref={this.calendarRef}></div>
        
      </Fragment>
    );
  }
}