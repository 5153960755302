import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react'
import ContactTypes from './ContactTypes';
import SalesStages from './SalesStages';
import RealityVersions from './RealityVersions';

export class Preferences extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <ContactTypes parentComponent={this} App={this.props.parentComponent} theme={this.props.parentComponent} />
                </Grid>
                <Grid item xs={12} md={6}>
                <SalesStages parentComponent={this} App={this.props.parentComponent} theme={this.props.parentComponent} />
                </Grid>
                <Grid item xs={12} p={10}>
                    <RealityVersions parentComponent={this} App={this.props.parentComponent} theme={this.props.parentComponent} />
                </Grid>
            </Grid>
        )
    }
}

export default Preferences