import React, { Fragment, Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SnackerBar from '../../components/SnackerBar';
import { Global, inlineStyles } from '../../helpers/global';
import { EnhancedTableToolbar } from '../../helpers/table_helper';
import { Box, Grid, MenuItem } from '@material-ui/core';
import 'date-fns';
import TextAutoSearch from '../../components/TextAutoSearch';
import { addressFormat } from '../../helpers/Helpers';
import { makeStyles } from "@material-ui/core/styles";


const url = 'contacts';

export default class EditContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.REACT_APP_API_HOST + url,
            id: this.props.parentComponent.state.selectedID,
            accountName: '',
            redirectTo: 'contact.details',
            notification: '',
            isLoading: true,
            isDataLoading: false,
            selectedID: 0,
            IdFromTextAutoSearch: 0,
            roles: [],
            objName: '',
            schema: [],
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            isEdit: true
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
   
    handleTextfieldChange = (name) => event => {

     if(name==='phone' || name==='mobile' || name==='assistantphone')  
     { 
            var cleaned = ('' + event.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
            if (match) 
            {
                var intlCode = (match[1] ? '+1 ' : ''),
                number = [intlCode, '', match[2], '- ', match[3], '-', match[4]].join('');
                Global.setStateValue(this, name, number);
                return;
            }
    }
   
        Global.setStateValue(this, name, event.target.value);
   
    };

    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }

    actionButtons = () => {
        return (
            <Box style={inlineStyles.toolbarActionBox}>
                <Button onClick={Global.handleSaveForm(this)} style={inlineStyles.button} color="primary" variant='contained' size="small">
                    <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Save
                        </Button>

                <Button onClick={this.backToParent} style={inlineStyles.buttonOrange} color="secondary" variant='contained' size="small">
                    <Icon className={"fas fa-arrow-circle-left"} style={inlineStyles.smallIcon}></Icon>Back to Contacts
                </Button>
            </Box>
        )
    }
    backToParent = () => {
        this.props.parentComponent.selectMenu('contacts', 0);
    }
    updateRowObj = (tabname, suggestion) => {
        if (tabname === 'offices') {
            this.setState({ accountName: suggestion.label });
            Global.setStateValue(this, 'accountid', suggestion.id);
        }
    }
    setAccountName = () => {
        var values = this.state.schema;        
        // eslint-disable-next-line
        values.find((row, i) => {
            if (row.name === 'accountname') {
                this.setState({ accountName: row.value });
                return true;
            }
        });    
    }
    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            Global.getSchema(this, true); /* if second parameter is true, the record to edit will be loaded (getOneRow()) */
        }
    }
    render() {
        const reportTitle = this.state.id ? 'Edit Contact' : 'New Contact';
        var values = this.state.schema;
        const useStyles = makeStyles(theme => ({
            root: {
              position: "relative"
            },
            display: {
              position: "absolute",
              top: 2,
              left: 0,
              bottom: 2,
              background: "white",
              pointerEvents: "none",
              right: 50,
              display: "flex",
              alignItems: "center"
            },
            input: {}
          }));
        function InputComponent({ defaultValue, inputRef, ...props }) {
            const classes = useStyles();
            const [value, setValue] = React.useState(() => props.value || defaultValue);
          
            const handleChange = event => {
              setValue(event.target.value);
              if (props.onChange) {
                props.onChange(event);
              }
            };
          
            return (
              <div className={classes.root}>
                <div className={classes.display}>{value}</div>
                <input
                  className={classes.input}
                  ref={inputRef}
                  {...props}
                  onChange={handleChange}
                  value={value}
                />
              </div>
            );
          }
          
        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <CircularProgress />
                            </div>
                        </Fragment>
                    }
                    {!this.state.isDataLoading &&
                        <Fragment>
                            <EnhancedTableToolbar
                                numSelected={0}
                                color="secondary"
                                toolbarTitle={reportTitle}
                                actionButtons={this.actionButtons}
                            />
                            <form autoComplete="off" style={inlineStyles.editform}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td width="33%" className="tableContainer">
                                                <table border={0}>
                                                    <tbody>
                                                        {values.filter((row) => (row.col === 1)).map((row, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="tdLabel">{row.label}: </td>
                                                                    <td className="tdEdit">
                                                                        {row.type !== 'noedit' ?
                                                                            (
                                                                                row.type === 'dropdown' ?
                                                                                    <TextField
                                                                                        select
                                                                                        error={row.error ? true : false}
                                                                                        variant="outlined"
                                                                                        margin="dense"
                                                                                        value={row.value}
                                                                                        onChange={this.handleTextfieldChange(row.name)}
                                                                                        required={row.required}
                                                                                        name={row.name}
                                                                                        disabled={row.isDisabled}
                                                                                    >
                                                                                        {row.selection.length > 0 && row.selection.map(option => (
                                                                                            <MenuItem key={option.key} value={option.key}>
                                                                                                {option.value}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                    :
                                                                                    (
                                                                                        row.type === 'searchbox' && row.name === 'accountid' ?
                                                                                            <TextAutoSearch
                                                                                                parentComponent={this.props.parentComponent}
                                                                                                updateRowObj={this.updateRowObj}
                                                                                                textValue={this.state.accountName}
                                                                                                tabname='offices' t={this} />
                                                                                            :
                                                                                            <TextField
                                                                                                error={row.error ? true : false}
                                                                                                variant="outlined"
                                                                                                margin="dense"
                                                                                                name={row.name}
                                                                                                type={row.type}
                                                                                                required={row.required}
                                                                                                onChange={this.handleTextfieldChange(row.name)}
                                                                                                value={row.numeric ? row.value.toString().replace(/[^0-9.-]/g,"").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : row.value} />
                                                                                    )
                                                                            )
                                                                            :
                                                                            (
                                                                                row.value
                                                                            )
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td width="34%" className="tableContainer">
                                                <table border={0}>
                                                    <tbody>
                                                        {values.filter((row) => (row.col === 2 && row.type !== 'hidden')).map((row, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="tdLabel">{row.label}: </td>
                                                                    <td className="tdEdit">
                                                                        {row.type !== 'noedit' ?
                                                                            (
                                                                                row.type === 'dropdown' ?
                                                                                    <TextField
                                                                                        select
                                                                                        error={row.error ? true : false}
                                                                                        variant="outlined"
                                                                                        margin="dense"
                                                                                        value={row.value}
                                                                                        onChange={this.handleTextfieldChange(row.name)}
                                                                                        required={row.required}
                                                                                        name={row.name}
                                                                                        disabled={row.isDisabled}
                                                                                    >
                                                                                        {row.selection.length > 0 && row.selection.map(option => (
                                                                                            <MenuItem key={option.key} value={option.key}>
                                                                                                {option.value}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </TextField>
                                                                                    :
                                                                                    (
                                                                                        row.type === 'searchbox' ?
                                                                                            <TextAutoSearch
                                                                                                parentComponent={this.props.parentComponent}
                                                                                                updateRowObj={this.updateRowObj}
                                                                                                textValue={values.filter((subrow) => (subrow.name === 'accountname')).map((subrow) => subrow.value)}
                                                                                                tabname='office'
                                                                                                t={this} />
                                                                                            :
                                                                                            ( row.type==='date'?

                                                                                            <TextField
                                                                                                error={row.error ? true : false}
                                                                                                variant="outlined"
                                                                                                margin="dense"
                                                                                                name={row.name}
                                                                                                type={row.type === 'date' ? 'date' : 'text'}
                                                                                                required={row.required}
                                                                                                InputProps={{
                                                                            
                                                                                                    inputComponent: InputComponent
                                                                            
                                                                                                }}
                                                                                                defaultValue="2020-01-01"
                                                                                                onChange={this.handleTextfieldChange(row.name)}
                                                                                                 value={row.value}/>
                                                                                            :
                                                                                            <TextField
                                                                                                error={row.error ? true : false}
                                                                                                variant="outlined"
                                                                                                margin="dense"
                                                                                                name={row.name}
                                                                                                type={row.type}
                                                                                                required={row.required}
                                                                                                onChange={this.handleTextfieldChange(row.name)}
                                                                                                value={row.value} />)
                                                                                    )
                                                                            )
                                                                            :
                                                                            (
                                                                                row.value
                                                                            )
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td width="33%" className="tableContainer">
                                                <table border={0}>
                                                    <tbody>
                                                        {values.filter((row) => (row.col === 3)).map((row, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="tdLabel">{row.label}: </td>
                                                                    <td className="tdEdit">
                                                                        {row.type !== 'noedit' ?
                                                                            row.type === 'date' ?
                                                                            <TextField
                                                                            error={row.error ? true : false}
                                                                            variant="outlined"
                                                                            margin="dense"
                                                                            name={row.name}
                                                                            type={row.type === 'date' ? 'date' : 'text'}
                                                                            required={row.required}
                                                                            disabled={row.isDisabled}
                                                                            InputProps={{
                                                                            
                                                                               inputComponent: InputComponent
                                                                            
                                                                              }}
                                                                            defaultValue="2020-01-01"
                                                                            onChange={this.handleTextfieldChange(row.name)}
                                                                            value={row.name==="cf_678" || row.name==="cf_677" || row.name==="cf_676" || row.name==="cf_675" || row.name==="cf_673" ? row.value.toString().replace(/[^0-9.-]/g,"").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : row.value}
                                                                            />
                                                                            :

                                                                            <TextField
                                                                                error={row.error ? true : false}
                                                                                variant="outlined"
                                                                                margin="dense"
                                                                                name={row.name}
                                                                                type={row.type === 'date' ? 'date' : 'text'}
                                                                                required={row.required}
                                                                                disabled={row.isDisabled}
                                                                                onChange={this.handleTextfieldChange(row.name)}
                                                                                value={row.name==="cf_678" || row.name==="cf_677" || row.name==="cf_676" || row.name==="cf_675" || row.name==="cf_673" ? row.value.toString().replace(/[^0-9.-]/g,"").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : row.value}
                                                                                />
                                                                            :
                                                                            row.value
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>
                                            <table border={0}>
                                                    <tbody>
                                                        <tr>
                                                        {values.filter((row) => (row.col === 4)).map((row, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <td className="tdLabel">{row.label}: </td>
                                                                    <td className="tdEdit" style={{minWidth:100}}>
                                                                        {row.type !== 'noedit' ?
                                                                            <TextField
                                                                                error={row.error ? true : false}
                                                                                variant="outlined"
                                                                                margin="dense"
                                                                                name={row.name}
                                                                                type={row.type === 'date' ? 'date' : 'text'}
                                                                                required={row.required}
                                                                                disabled={row.isDisabled}
                                                                                onChange={this.handleTextfieldChange(row.name)}
                                                                                value={row.name==="mailingstreet" ? addressFormat(row.value) : row.value} 
                                                                               
                                                                                />
                                                                            :
                                                                            row.value
                                                                        }
                                                                    </td>
                                                                </Fragment>
                                                            )
                                                        })
                                                        }
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Grid container spacing={2} style={{ marginTop: 10 }}>
                                    {values.filter((row) => row.col === 0).map((row, index) => {
                                        return (
                                            <Grid item xs={12} md={row.gridsize ? row.gridsize : 6} key={index}>
                                                {row.type === 'date' ?
                                                    <TextField
                                                        type="date"
                                                        error={row.error ? true : false}
                                                        variant="outlined"
                                                        margin="dense"
                                                        label={row.label}
                                                        name={row.name}
                                                        required={row.required}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            inputComponent: InputComponent
                                                          }}
                                                        defaultValue="2020-01-01"
                                                        onChange={this.handleTextfieldChange(row.name)}
                                                        value={row.value} />
                                                    :
                                                    <TextField
                                                        error={row.error ? true : false}
                                                        variant="outlined"
                                                        margin="dense"
                                                        multiline
                                                        fullWidth={true}
                                                        rows={3}
                                                        label={row.label}
                                                        name={row.name}
                                                        required={row.required}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleTextfieldChange(row.name)}
                                                        value={row.value} />
                                                }
                                            </Grid>
                                        )
                                    })
                                    }
                                </Grid>
                            </form>
                        </Fragment>
                    }

                </Paper>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}

