import React, { Fragment, Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import 'date-fns';
import { EnhancedTableToolbar, EnhancedTableHead } from '../../helpers/table_helper';
import { Global, inlineStyles } from '../../helpers/global';
import SnackerBar from '../../components/SnackerBar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Link, Grid } from '@material-ui/core';

const reportTitle = "Contacts";
const EntityName = 'Contact';
const editPage = 'contact.edit';

export default class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.REACT_APP_API_HOST + 'contacts',
            count: 1,
            deleteDialogOpen: false,
            dialogAddEditOpen: false,
            dense: false,
            id: 0,
            isLoading: true,
            isDataLoading: false,
            isDialogDataLoading: false,
            notification: '',
            objName: '',
            order: 'asc',
            orderBy: '',
            page: 0,
            pageTotal: 0,
            rows: [],
            //NT change row per page from 20 to 100
            rowsPerPage: 100,
            searchFor: '',
            selected: [],
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            showClearSearchBtn: false,
            schema: [],
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }
    handleTextfieldChange = (name, key) => e => {
        var values = { ...this.state.schema };
        values[key].value = e.target.value;
        this.setState({ values });
    }
    handleRequestSort = (event, property) => {
        var isDesc = this.state.order === 'desc';
        var order = isDesc || this.state.orderBy !== property ? 'asc' : 'desc';
        var values = {
            order: order,
            orderBy: property,
        };
        this.setComponentState('order', order);
        this.setComponentState('orderBy', property);
        Global.loadList(this, values);
    }
    setSearch = (e) => {
        var v = e.target.value;
        this.setState({ searchFor: v });
        if (v) {
            this.setComponentState('showClearSearchBtn', true);
        } else {
            this.setComponentState('showClearSearchBtn', false);
        }
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            var newSelecteds = this.state.rows.map(n => n.name);
            this.setComponentState('selected', newSelecteds);
            return;
        }
        this.setComponentState('selected', []);
    }

    handleChangePage = (event, newPage) => {
        this.setComponentState('page', newPage);
        Global.loadList(this, { page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setComponentState('rowsPerPage', event.target.value);
        Global.loadList(this, { rowsPerPage: event.target.value });
    }

    handleChangeDense = (event) => {
        this.setComponentState('dense', event.target.checked);
    }

    handleDialogClose = () => {
        this.setDeleteDialogOpen(false);
    }
    confirmDeleteRow(id, name) {
        var states = this.state;
        states.id = id;
        states.objName = name;
        states.deleteDialogOpen = true;
        this.setState({ states });
    }
    confirmDeletion = (id) => {
        Global.deleteRow(this, id);
    }
    setDeleteDialogOpen = (v) => {
        if (!v) {
            Global.loadList(this, { isDataLoading: false });
        }
        this.setState({ deleteDialogOpen: v });
    }
    setEditDialogOpen = (v) => {
        this.setState({ dialogAddEditOpen: v });
        if (!v) {
            var formValues = this.state.schema;
            var values = { ...this.state.schema };
            formValues.forEach((row, key) => {
                values[key].error = '';
                values[key].value = '';
                this.setState({ values });
            })
            Global.loadList(this, { isDataLoading: false });
        }
    }
    showDetails = (id) => {
        let oId = { idFor: 'selectedID', value: id };
        this.props.parentComponent.selectMenu('contact.details', oId, {});
    }
    editRow = (id) => {
        let oId = { idFor: 'selectedID', value: id };
        this.props.parentComponent.selectMenu(editPage, oId, {});
        /* Use this 2 lines below instead of you want to edit in a dialog box */
        // Global.getOneRow(this, id);
        // this.setEditDialogOpen(true);
    }

    addNew = () => {
        this.editRow(0);
        /* Use the codes below if you want popup for editing */
        // var values = { ...this.state.schema };
        // values.id = 0;
        // this.setState({ values });
        // this.setEditDialogOpen(true);
    }
    mergeContacts = () => {
        this.props.parentComponent.selectMenu('contact.merge');
    }

    actionButtons = () => {
        return (
            <Fragment>
                <Button onClick={this.addNew} style={inlineStyles.buttonBlue} variant='contained' size="small">
                    <i className="material-icons" style={{ marginRight: 5, fontSize: 16 }}>person_add</i> <span> New {EntityName}</span>
                </Button>&nbsp;&nbsp;
                <Button onClick={this.mergeContacts} style={inlineStyles.buttonOrange} variant='contained' size="small">
                    <i className="material-icons" style={{ marginRight: 5, fontSize: 16 }}>vertical_align_center</i> <span> Merge {EntityName}s</span>
                </Button>
            </Fragment>
        )
    }
    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            Global.getSchema(this, false);
        }
    }
    render() {
        var headRows = [];
        var formValues = this.state.schema;
        formValues.forEach((row) => {
            headRows.push({ id: row.name, label: row.label, numeric: row.numeric, showInList: row.showInList });
        });

        headRows.push({ id: 'action', label: 'Actions', numeric: false, showInList: true });
        var permissions = this.props.parentComponent.state.permissions;

        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }

                    <Fragment>
                        <EnhancedTableToolbar
                            numSelected={this.state.selected ? this.state.selected.length : 0}
                            toolbarTitle={reportTitle}
                            showSearchBox={true}
                            actionButtons={this.actionButtons}
                            t={this}
                            Global={Global}
                        />
                        {!this.state.isDataLoading &&
                            <Fragment>
                                <div style={inlineStyles.tableWrapper}>
                                    <Table
                                        style={inlineStyles.table}
                                        aria-labelledby="tableTitle"
                                        size={this.state.dense ? 'medium' : 'small'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={this.state.selected ? this.state.selected.length : 0}
                                            order={this.state.order}
                                            orderBy={this.state.orderBy}
                                            onSelectAllClick={false}
                                            onRequestSort={this.handleRequestSort}
                                            headRows={headRows}
                                        />
                                        <TableBody>
                                            {this.state.rows && this.state.rows.length > 0 && this.state.rows.map((row, index) => {
                                                const labelId = `table-row-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={index}
                                                    >
                                                        <TableCell className="listRowNum" id={labelId} scope="row" padding="none" align="center">
                                                            {index + 1 + (this.state.page * this.state.rowsPerPage)}
                                                        </TableCell>
                                                        {formValues.filter(col => col.showInList).map((col, colindex) => {
                                                            const colval = colindex
                                                                ? row[col.name]
                                                                : (<Link to="#" onClick={() => this.showDetails(row.crmid)} aria-label="Show contact details">{row[col.name]}</Link>);
                                                            return (
                                                                <TableCell key={colindex} align={col.numeric ? 'right' : (col.align ? col.align : 'left')}>
                                                                    {colval}
                                                                </TableCell>
                                                            );
                                                        })
                                                        }

                                                        <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                                                            <IconButton style={inlineStyles.iconButton} onClick={() => this.showDetails(row.crmid)} aria-label="Edit row" size="small">
                                                                <Icon className={'fas fa-edit fa-small '} color="primary" />
                                                            </IconButton>
                                                            {(permissions.Contacts.indexOf('f') !== -1 || permissions.Contacts.indexOf('d') !== -1) &&
                                                                <IconButton style={inlineStyles.iconButton} onClick={() => this.confirmDeleteRow(row.crmid, row.firstname + ' ' + row.lastname)} aria-label="Delete row" size="small">
                                                                    <Icon className={'fas fa-trash-alt fa-small'} color="error" />
                                                                </IconButton>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                            }
                                            {this.state.rows && !this.state.rows.length &&
                                                <TableRow><TableCell align="center" colSpan={8}>{"No result"}</TableCell></TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                                {this.state.pageTotal > 1 &&
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        component="div"
                                        count={this.state.count}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                }
                            </Fragment>
                        }
                    </Fragment>
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ padding: 50, textAlign: 'center' }}>
                                {this.props.parentComponent.loadingCircular()}
                            </div>
                        </Fragment>
                    }
                </Paper>

                <Dialog
                    open={this.state.deleteDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description"></DialogContentText>
                        <div style={{ padding: 15 }}>
                            Do you want to continue deleting "{this.state.objName}"?
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setDeleteDialogOpen(false)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                            No
                    </Button>
                        <Button disabled={this.state.isDataLoading} onClick={() => this.confirmDeletion(this.state.id)} variant='contained' color="primary" size="small" style={inlineStyles.button} autoFocus>
                            Yes
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.dialogAddEditOpen}
                    aria-labelledby="alert-dialog2-title"
                    aria-describedby="alert-dialog2-description"
                >
                    <DialogTitle id="alert-dialog2-title">{this.state.id ? "Edit " + EntityName : "Add a " + EntityName}</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog2-description">* Required</DialogContentText>
                        <Fragment>

                            {
                                this.state.notification &&
                                <Typography paragraph color="error" align="center" style={{ 'padding': 5 }}>
                                    {this.state.notification}
                                </Typography>
                            }
                            {
                                this.state.isDialogDataLoading &&
                                <div style={{ padding: 20, minWidth: 350, textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>
                            }
                            {!this.state.isDialogDataLoading &&
                                <Fragment>
                                    <Grid container spacing={2}>
                                        {formValues.map((row, index) => {
                                            return (
                                                <Grid item xs={12} md={4} key={index}>
                                                    <TextField
                                                        error={row.error ? true : false}
                                                        variant="outlined"
                                                        margin="dense"
                                                        name={row.name}
                                                        required={row.required}
                                                        label={row.error ? row.error : row.label}
                                                        value={row.value}
                                                        onChange={this.handleTextfieldChange(row.name, index)}
                                                        disabled={this.state.isDialogDataLoading}
                                                    />
                                                </Grid>
                                            )
                                        })
                                        }
                                    </Grid>
                                </Fragment>
                            }

                        </Fragment>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={Global.handleSaveForm(this)} variant='contained' color="primary" size="small" style={inlineStyles.button} autoFocus>
                            Save
                    </Button>
                        <Button onClick={() => this.setEditDialogOpen(false)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                            Cancel
                    </Button>
                    </DialogActions>
                </Dialog>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}
