import React, { Fragment, Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SnackerBar from '../../components/SnackerBar';
import { Global, inlineStyles } from '../../helpers/global';
import { EnhancedTableToolbar } from '../../helpers/table_helper';
import { Box, Grid, Dialog, DialogContent, TextField } from '@material-ui/core';
import 'date-fns';
import Events from '../Events/Events';
import EditEvent from '../Events/EditEvent';
import axios from "axios";
import { numberFormat } from '../../helpers/Helpers';
import { addressFormat } from '../../helpers/Helpers';
//import { makeStyles } from "@material-ui/core/styles";

const url = 'contacts';

export default class ContactDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.REACT_APP_API_HOST + url,
            id: this.props.parentComponent.state.selectedID,
            notification: '',
            isLoading: true,
            isDataLoading: false,
            dialogEventEditOpen: false,
            loadingCircular: false,
            selectedID: 0,
            roles: [],
            objName: '',
            event: {},
            eventMode: 'add',
            reloadEvent: false,
            reloadEvents: false,
            schema: [],
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
           
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    handleTextfieldChange = (name) => event => {
        Global.setStateValue(this, name, event.target.value);
        return;
    };
    handleAddDateTime=(name,alreadyText)=>event=>
    {

        let tempDate = new Date();
        let date = tempDate.getFullYear() + '-' + ((tempDate.getMonth()+1)<10 ? '0'+(tempDate.getMonth()+1) :(tempDate.getMonth()+1)) + '-' + (tempDate.getDate()<10 ? '0'+tempDate.getDate() : tempDate.getDate()) +' '+ (tempDate.getHours()<10 ? '0'+tempDate.getHours() : tempDate.getHours())+':'+ (tempDate.getMinutes()<10 ? '0'+tempDate.getMinutes() : tempDate.getMinutes())+':'+ (tempDate.getSeconds()<10 ? '0'+tempDate.getSeconds() : tempDate.getSeconds()); 
        let user = JSON.parse(sessionStorage.getItem('userdata'));
        const str='\n\n';
        const currDate = user.userdata.firstname+' '+user.userdata.lastname+' - '+date+' - '+str+alreadyText;
        Global.setStateValue(this, name, currDate);
        return;

    };
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }

    setBranch = (v) => {
        var values = { ...this.state.schema }
        values.branchId = v.target.value;
        this.setState({ values });
    }
    editRow = (id) => {
        let oId = { idFor: 'selectedID', value: this.state.id };
        this.props.parentComponent.selectMenu('contact.edit', oId, {});
    }
    addEvent = () => {
        var values = this.state.schema;
        var contactname;
        if (values.length>1) {
            contactname = values[0].value+' '+values[1].value;
        }
        var states = this.state;
        states['event'] = {contactid: this.state.id, contact: contactname};        
        states['dialogEventEditOpen'] = true;
        states['reloadEvent'] = true;
        states['eventMode'] = 'add';
        this.setState({ states });
    }
    editEvent = (id) => {        
        var user = JSON.parse(sessionStorage.getItem('userdata'));
        if (typeof (user.token) === 'undefined' && !user.token) return;

        var states = this.state;
        states.loadingCircular = true;
        states.notification = '';        
        this.setState({ states });

        var formData = new FormData();
        formData.append('token', user.token);
        formData.append('user_id', user.userdata.id);
        formData.append('id', id);
        formData.append('action', 'selectById');

        axios(
            {
                method: 'post',
                url: process.env.REACT_APP_API_HOST + 'events',
                crossdomain: true,
                data: formData,
            })
            .then(res => res.data)
            .then(data => {
                if (data.hasOwnProperty('status') === false || data.status !== 'success') {
                    let error = `Error: ${data.message}`;
                    this.openSnackBar(error, 'error', true);     
                    this.setState({  dialogEventEditOpen: false });
                } else {
                    if (data.rows) {               
                        this.setState({ ...this.state, event: data.rows, dialogEventEditOpen: true, reloadEvent: true, eventMode: 'edit' });
                    } else {
                        this.setState({ ...this.state, dialogEventEditOpen: false  });
                        this.openSnackBar('Error fetching data', 'error', true);    
                    }
                }
            })
            .catch(e => {
                let error = `Error caught: ${e}`;
                this.openSnackBar(error, 'error', true);                  
            }).finally(()=>{
                this.setState({ loadingCircular: false });
            });
    }
    saveEvent = (eventvalues) => {        
        var values = [];
        eventvalues.forEach((row)=>{
            values.push({name:row.name, value: row.value, required: row.required});
        })
        values.push({name:'action', value: 'save', required: false});   
        values.push({name:'id', value: this.state.event.id, required: false});                
        let url = process.env.REACT_APP_API_HOST + 'events';                  
        Global.SaveDialogFormData(this, url, values, 'events', this.reloadEvents);
    }
    reloadEvents = (id) => {
        this.setState({ ...this.state, dialogEventEditOpen: false, reloadEvents: true });
    }

    actionButtons = () => {
        return (
            <Box style={inlineStyles.toolbarActionBox}>                
                <Button onClick={() => this.editRow()} style={inlineStyles.button} color="primary" variant='contained' size="small">
                    <Icon className="fas fa-pencil-alt" style={inlineStyles.smallIcon}></Icon>Edit
                </Button>

                <Button onClick={this.backToParent} style={inlineStyles.buttonOrange} color="secondary" variant='contained' size="small">
                    <Icon className={"fas fa-arrow-circle-left"} style={inlineStyles.smallIcon}></Icon>Back to Contacts
                </Button>
            </Box>
        )
    }
    backToParent = () => {
        this.props.parentComponent.selectMenu('contacts', 0);
    }

    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            //Global.getDataRows(this, process.env.REACT_APP_ROLES_URL, 'roles');
            Global.getSchema(this, true); /* if second parameter is true, the record to edit will be loaded (getOneRow()) */
        }
    }
    render() {
        const reportTitle = 'Contact Details';
        var values = this.state.schema;

      /*  const useStyles = makeStyles(theme => ({
            root: {
              position: "relative"
            },
            display: {
              position: "absolute",
              top: 2,
              left: 0,
              bottom: 2,
              background: "white",
              pointerEvents: "none",
              right: 50,
              display: "flex",
              alignItems: "center"
            },
            input: {}
          }));
        function InputComponent({ defaultValue, inputRef, ...props }) {
            const classes = useStyles();
            const [value, setValue] = React.useState(() => props.value || defaultValue);
          
            const handleChange = event => {
              setValue(event.target.value);
              if (props.onChange) {
                props.onChange(event);
              }
            };
          
            return (
              <div className={classes.root}>
                <div className={classes.display}>{value}</div>
                <input
                  className={classes.input}
                  ref={inputRef}
                  {...props}
                  onChange={handleChange}
                  value={value}
                />
              </div>
            );
          }*/


        return (
            <Fragment>
                <Paper style={{ marginBottom: 10 }}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <CircularProgress />
                            </div>
                        </Fragment>
                    }
                    {!this.state.isDataLoading &&
                        <Fragment>
                            <EnhancedTableToolbar
                                numSelected={0}
                                toolbarTitle={reportTitle}
                                actionButtons={this.actionButtons}
                            />
                            <form autoComplete="off" style={inlineStyles.editform}>

                                <table>
                                    <tbody>
                                        <tr>
                                            <td width="33%" className="tableContainer">
                                                <table border={0}>
                                                    <tbody>
                                                        {values.filter((row) => (row.col === 1)).map((row, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="tdLabel">{row.label}: </td>
                                                                    <td className="tdContent"><b>{row.name==='cf_650' ? row.value===0 || row.value==='' || row.value==='0.00'  ? '' : numberFormat(row.value,2) : row.value}</b></td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td width="34%" className="tableContainer">
                                                <table border={0}>
                                                    <tbody>
                                                        {values.filter((row) => (row.col === 2)).map((row, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="tdLabel">{row.label}: </td>
                                                                    <td className="tdContent"><b>{row.value==='0000-00-00'? '' : row.numeric ?  numberFormat(row.value,2) : row.value}</b></td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td width="33%" className="tableContainer">
                                                <table border={0}>
                                                    <tbody>
                                                        {values.filter((row) => (row.col === 3)).map((row, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="tdLabel">{row.label}: </td>
                                                                    <td className="tdContent"><b>{row.name==='cf_678' || row.name==='cf_677' || row.name==='cf_676' || row.name==='cf_675' || row.name==='cf_673' ? row.value===0 || row.value==='' || row.value==='0.00'  ? '' : numberFormat(row.value,2) :  row.value==='0000-00-00' ? '' :row.value}</b></td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} className="tableContainer">
                                            <table border={0}>
                                                    <tbody>
                                                        <tr>
                                                        {values.filter((row) => (row.col === 4)).map((row, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <td className="tdLabel">{row.label}: </td>
                                                                    <td className="tdContent" style={{minWidth:100}}><b>{row.name==="mailingstreet" ? addressFormat(row.value) : row.value}</b></td>
                                                                </Fragment>
                                                            )
                                                        })
                                                        }
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            
                                <Grid container spacing={2} style={{ marginTop: 10 }}>
                                    {values.filter((row) => row.col === 0).map((row, index) => {
                                        return (
                                            <Grid item xs={12} style={{maxHeight: 200, overflow: 'auto'}} md={row.gridsize ? row.gridsize : 6} key={index}>
                                              {row.label === 'POA Date'?
                                              /*  <TextField
                                                    type="date"
                                                    variant="outlined"
                                                    margin="dense"
                                                    error={row.error ? true : false}
                                                    label={row.label}
                                                    name={row.name}
                                                    value={row.value==='0000-00-00' ? '' : row.value }
                                                    required={row.required}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        inputComponent: InputComponent
                                                      }}
                                                    defaultValue="2020-01-01"
                                                    onChange={this.handleTextfieldChange(row.name)}
                                               

                                                />*/'':
                                            <table>
                                                <tr>
                                                    <td>
                                                    <Button onClick={this.handleAddDateTime(row.name,row.value)} style={{width:"300px"}} color="primary" variant='contained' size="small">
                                                     Insert Date and Time
                                                     </Button>
                                                    
                                                     </td>
                                                </tr>
                                                <tr><td><br/></td></tr>
                                                <tr>
                                                    <td>
                                                    <TextField
                                                        type="text"
                                                        variant="outlined"
                                                        margin="dense"
                                                        error={row.error ? true : false}
                                                        label={row.label}
                                                        name={row.name}
                                                        value={row.value}
                                                        required={row.required}
                                                        multiline
                                                        fullWidth={true}
                                                        rows={3}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                         }}
                                                        onChange={this.handleTextfieldChange(row.name)}
                                               

                                                    />
                                                    </td>
                                                </tr>
                                                </table>
                                                }
                                               
                                                   
                                                    
                                                    
                                                 
                                            </Grid>
                                            
                                        )
                                    })
                                    }
                                </Grid>
                              
                                <table>
                                    <tr>
                                        <td align="right">
                                        <Button onClick={Global.handlePofaNotesForm(this)} style={{width:"100px"}} color="primary" variant='contained' size="small">
                                            <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Save
                                        </Button>
                                        </td>
                                </tr>
                                </table>
                            </form>
                        </Fragment>
                    }

                </Paper>

                <Paper style={{ marginBottom: 30 }}>
                    <Events parentComponent={this} App={this.props.parentComponent} reloadEvents={this.state.reloadEvents} theme={this.props.theme} />
                    {this.state.dialogEventEditOpen &&
                    <EditEvent parentComponent={this} App={this.props.parentComponent} reloadEvent={this.state.reloadEvent} theme={this.props.theme} />
                    }
                </Paper>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
                <Dialog
                    open={this.state.loadingCircular}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        {this.props.parentComponent.loadingCircular()}
                    </DialogContent>
               </Dialog>
            </Fragment>
        );
    }
}

