import React, { Fragment, Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SnackerBar from '../../components/SnackerBar';
import { Global, inlineStyles } from '../../helpers/global';
import { EnhancedTableToolbar } from '../../helpers/table_helper';
import { Box, MenuItem, Grid } from '@material-ui/core';

import 'date-fns';

const url = 'profile';

export default class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.REACT_APP_API_HOST + url,
            id: props.parentComponent.state.selectedID,
            isEdit: 1,
            notification: '',
            isLoading: true,
            isDataLoading: false,
            roles: [],
            objName: '',
            schema: [],
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    handleTextfieldChange = (name) => event => {
        Global.setStateValue(this, name, event.target.value);
        return;
    };
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }

    setBranch = (v) => {
        var values = { ...this.state.schema }
        values.branchId = v.target.value;
        this.setState({ values });
    }

    actionButtons = () => {
        return (
            <Box style={inlineStyles.toolbarActionBox}>
                <Button onClick={Global.handleSaveForm(this)} style={inlineStyles.button} color="primary" variant='contained' size="small">
                    <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Save
                </Button>

                <Button onClick={this.handleCancel} style={inlineStyles.button} color="secondary" variant='contained' size="small">
                    <Icon className={"fas fa-times-circle"} style={inlineStyles.smallIcon}></Icon>Cancel
                </Button>
            </Box>
        )
    }
    handleCancel = () => {
        this.props.parentComponent.selectMenu('contacts', 0);
    }

    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            Global.getSchema(this, true); /* if second parameter is true, the record to edit will be loaded (getOneRow()) */
        }
    }
    render() {
        const reportTitle = 'My Profile';
        var values = this.state.schema;
        
        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <CircularProgress />
                            </div>
                        </Fragment>
                    }
                    {!this.state.isDataLoading &&
                        <Fragment>
                            <EnhancedTableToolbar
                                numSelected={0}
                                toolbarTitle={reportTitle}
                                actionButtons={this.actionButtons}
                            />
                            <form autoComplete="off" style={inlineStyles.editform}>
                                <Grid container spacing={2}>
                                    {values.filter((row) => (row.type !== '')).map((row, index) => {
                                        return (
                                            <Grid item xs={12} md={row.gridsize ? row.gridsize : 4} key={index}>
                                                {row.type === 'dropdown' ?
                                                    <TextField
                                                        select
                                                        variant="outlined"
                                                        type={row.type}
                                                        name={row.name}
                                                        required={row.required}
                                                        label={row.error ? row.error : row.label}
                                                        value={row.value}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleTextfieldChange(row.name)}
                                                        disabled={this.state.isDataLoading}
                                                    >
                                                        {row.selection.length > 0 && row.selection.map(option => (
                                                            <MenuItem key={option.key} value={option.key}>
                                                                {option.value}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    :
                                                    <TextField
                                                        error={row.error ? true : false}
                                                        variant="outlined"
                                                        autoFocus={row.autofocus}
                                                        autoComplete={false}
                                                        type={row.type}
                                                        name={row.name}
                                                        required={row.required}
                                                        label={row.error ? row.error : row.label}
                                                        value={row.value}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleTextfieldChange(row.name)}
                                                        disabled={this.state.isDataLoading}
                                                    />
                                                }
                                            </Grid>
                                        )
                                    })
                                    }
                                    
                                </Grid>
                            </form>
                        </Fragment>
                    }

                </Paper>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}

