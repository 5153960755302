export function getCurDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}
export function numberFormat(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e);
    }
};

export function addressFormat(addresstxt)
{
    try 
    {
        var splitedAddress=addresstxt.split(" ");
        if(splitedAddress.length>1)
        {
            var str="";
            for(var i=1;i<splitedAddress.length;i++)
            {
                str=str+" "+splitedAddress[i];
            }
            return str+" "+splitedAddress[0];
        }
        else
        {
             return addresstxt;
        }
    }catch(e){
        console.log(e);
    }

};


  

  