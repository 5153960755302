import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function MaterialUIPickers(props) {

  function handleDateChange(date) {    
    props.parentComponent.handleChangeDate('date_start',date.toJSON());
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>      
        <KeyboardDatePicker
          margin="dense"
          className="datePicker"
          id="date-picker-dialog"
          format="yyyy-MM-dd"
          label="Date"
          value={props.dateValue}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'Choose date',
          }}
        />
    </MuiPickersUtilsProvider>
  );
}