import React, { Fragment, Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'date-fns';
import { EnhancedTableToolbar, EnhancedTableHead } from '../../helpers/table_helper';
import { Global, inlineStyles } from '../../helpers/global';
import SnackerBar from '../../components/SnackerBar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';

const reportTitle = "Merge Contacts";
// eslint-disable-next-line
const options = [
    { value: 'firstname', label: 'Firstname' },
    { value: 'lastname', label: 'Lastname' },
    { value: 'office', label: 'Office' },
];

export default class MergeContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.REACT_APP_API_HOST + 'contacts.merge',
            count: 1,
            deleteDialogOpen: false,
            dialogAddEditOpen: false,
            dense: false,
            id: 0,
            isLoading: true,
            isDataLoading: false,
            isDialogDataLoading: false,
            notification: '',
            objName: '',
            order: 'asc',
            orderBy: '',
            page: 0,
            pageTotal: 0,
            rows: [],
            rowsPerPage: 20,
            searchFor: '',
            selected: [],
            selectedCol: {},
            checkedBox: {firstname: true, lastname: true, accountid: false},
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            showClearSearchBtn: false,
            schema: [],
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }
    
    handleRadioChange = (index) => (e) => {        
        var selectedCol = this.state.selectedCol;
        selectedCol[e.target.name] = index;
        this.setState({ selectedCol });
        Global.setStateValue(this,e.target.name,e.target.value);        
    }
    handleCheckboxChange = chkBoxName => e => {
        var chkName = chkBoxName;
        var chkBoxes = this.state.checkedBox;
        chkBoxes[chkName] = e.target.checked;
        this.setState({ checkedBox: chkBoxes });        
        Global.loadList(this, {});
    };
    handleRequestSort = (event, property) => {
        var isDesc = this.state.order === 'desc';
        var order = isDesc || this.state.orderBy !== property ? 'asc' : 'desc';
        var values = {
            order: order,
            orderBy: property,
        };
        this.setComponentState('order', order);
        this.setComponentState('orderBy', property);
        Global.loadList(this, values);
    }
    setSearch = (e) => {
        var v = e.target.value;
        this.setState({ searchFor: v });
        if (v) {
            this.setComponentState('showClearSearchBtn', true);
        } else {
            this.setComponentState('showClearSearchBtn', false);
        }
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            var newSelecteds = this.state.rows.map(n => n.name);
            this.setComponentState('selected', newSelecteds);
            return;
        }
        this.setComponentState('selected', []);
    }

    handleChangePage = (event, newPage) => {
        this.setComponentState('page', newPage);
        Global.loadList(this, { page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setComponentState('rowsPerPage', event.target.value);
        Global.loadList(this, { rowsPerPage: event.target.value });
    }

    handleChangeDense = (event) => {
        this.setComponentState('dense', event.target.checked);
    }

    handleDialogClose = () => {
        this.setDeleteDialogOpen(false);
    }
    confirmDeleteRow(id, name) {
        var states = this.state;
        states.id = id;
        states.objName = name;
        states.deleteDialogOpen = true;
        this.setState({ states });
    }
    confirmDeletion = (id) => {
        Global.deleteRow(this, id);
    }
    setDeleteDialogOpen = (v) => {
        if (!v) {
            Global.loadList(this, { isDataLoading: false });
        }
        this.setState({ deleteDialogOpen: v });
    }
    setEditDialogOpen = (v) => {
        this.setState({ dialogAddEditOpen: v });
        if (!v) {
            var formValues = this.state.schema;
            var values = { ...this.state.schema };
            formValues.forEach((row, key) => {
                values[key].error = '';
                values[key].value = '';
                this.setState({ values });
            })
            Global.loadList(this, { isDataLoading: false });
        }
    }
    
    showDetails = (id) => {        
         Global.getOneRow(this, id);
         this.setEditDialogOpen(true);
    }

    actionButtons = () => {
        return (

            <div style={{ display: 'flex' }}>
                <div style={{ flex: '0 0 auto', paddingTop: 11, paddingLeft: 20, }}>
                Column Group(s):
                
                </div>
                <div style={{ flex: '0 1 auto' }}>
                </div>
                <div style={{ flex: '0 0 auto' }}>
                
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={this.state.checkedBox.firstname}
                        onChange={this.handleCheckboxChange('firstname')}
                        value="firstname"
                        color="primary"
                    />
                    }
                    label="First Name"
                />
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={this.state.checkedBox.lastname}
                        onChange={this.handleCheckboxChange('lastname')}
                        value="lastname"
                        color="primary"
                    />
                    }
                    label="Last Name"
                />
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={this.state.checkedBox.office}
                        onChange={this.handleCheckboxChange('accountid')}
                        value="office"
                        color="primary"
                    />
                    }
                    label="Office"
                />
                </div>
                
            </div>
        )
    }
    handleClick = (value,event) => {
        var selected = this.state.selected;
        const selectedIndex = selected.indexOf(value);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    }

    isSelected = (name) => this.state.selected.indexOf(name) !== -1;
    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            Global.getSchema(this, false);
        }
    }
    render() {
        var headRows = [];
        var selectedCol = this.state.selectedCol;
        var formValues = this.state.schema;        
        formValues.forEach((row) => {
            headRows.push({ id: row.name, label: row.label, numeric: row.numeric, showInList: row.showInList });
            if(!selectedCol.hasOwnProperty(row.name)) {
                selectedCol[row.name] = 0;
            }
        });

        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }

                    <Fragment>
                        <EnhancedTableToolbar
                            numSelected={this.state.selected ? this.state.selected.length : 0}
                            toolbarTitle={reportTitle}
                            showSearchBox={true}
                            actionButtons={this.actionButtons}
                            t={this}
                            Global={Global}
                        />
                        {!this.state.isDataLoading && !this.state.dialogAddEditOpen &&
                            <Fragment>
                                <div style={inlineStyles.tableWrapper}>
                                {this.state.selected.length > 1 &&
                                <div style={{textAlign:'left',padding:15}}>
                                    <Button onClick={()=>this.showDetails(1)} color="secondary" variant='contained' size="small">
                                        <i className="material-icons" style={{ marginRight: 5, fontSize: 16 }}>vertical_align_center</i> <span> Merge Selected</span>
                                    </Button>
                                </div>
                                }
                                    <Table
                                        style={inlineStyles.table}
                                        aria-labelledby="tableTitle"
                                        size={this.state.dense ? 'medium' : 'small'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={this.state.selected ? this.state.selected.length : 0}
                                            order={this.state.order}
                                            orderBy={this.state.orderBy}
                                            onSelectAllClick={false}
                                            onRequestSort={this.handleRequestSort}
                                            headRows={headRows}
                                        />
                                        <TableBody>
                                            {this.state.rows && this.state.rows.length > 0 && this.state.rows.map((row, index) => {
                                                const labelId = `table-row-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={index}
                                                    >
                                                        <TableCell className="listRowNum" id={labelId} scope="row" padding="none" align="center">
                                                        <Checkbox
                                                                onClick={event => this.handleClick(row.crmid,event)}
                                                                checked={this.isSelected(row.crmid)}
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </TableCell>
                                                        {formValues.filter(col => col.showInList).map((col, colindex) => {                           return (
                                                                <TableCell key={colindex} align={col.numeric ? 'right' : (col.align ? col.align : 'left')}>
                                                                    {row[col.name]}
                                                                </TableCell>
                                                            );
                                                        })
                                                        }

                                                    </TableRow>
                                                );
                                            })
                                            }
                                            {this.state.rows && !this.state.rows.length &&
                                                <TableRow><TableCell align="center" colSpan={11}><h3>Search for contact records to merge</h3></TableCell></TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                                {this.state.pageTotal > 1 &&
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        component="div"
                                        count={this.state.count}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                }
                            </Fragment>
                        }
                    </Fragment>
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ padding: 50, textAlign: 'center' }}>
                                {this.props.parentComponent.loadingCircular()}
                            </div>
                        </Fragment>
                    }
                </Paper>

                <Dialog
                    open={this.state.dialogAddEditOpen}
                    aria-labelledby="alert-dialog2-title"
                    fullWidth={true}
                    aria-describedby="alert-dialog2-description"
                >
                    <DialogTitle id="alert-dialog2-title">Merge Contacts</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog2-description">Choose the column in each row to keep</DialogContentText>
                        <Fragment>
                            {
                                this.state.notification &&
                                <Typography paragraph color="error" align="center" style={{ 'padding': 5 }}>
                                    {this.state.notification}
                                </Typography>
                            }
                            {
                                this.state.isDialogDataLoading &&
                                <div style={{ padding: 20, minWidth: 350, textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>
                            }
                            
                            <Grid container spacing={0}>   
                            <Grid item xs={4}>                    
                                {formValues.filter(col => col.type!=='hidden').map((col, colindex) => {                           
                                    return (
                                        <Table
                                            style={inlineStyles.table}
                                            aria-labelledby="tableTitle"
                                            size='small'         
                                            key={colindex}                                           
                                        >
                                        <TableBody>
                                        <TableRow
                                            hover
                                        > 
                                        <TableCell style={{height:30, fontWeight:'bold'}}>
                                            {col.label}:
                                        </TableCell>
                                        </TableRow>
                                        </TableBody>
                                        </Table> 
                                    );
                                })
                                }
                            </Grid>    
                            {!this.state.isDialogDataLoading && this.state.dialogAddEditOpen &&
                                this.state.rows && this.state.rows.length > 0 && this.state.rows.map((row, index) => {     return (
                                    <Grid item xs={4} key={index}>                    
                                        {formValues.filter(col => col.type!=='hidden').map((col, colindex) => {  
                                            return (
                                                <Table
                                                    style={inlineStyles.table}
                                                    aria-labelledby="tableTitle"
                                                    size='small'       
                                                    key={colindex+'-'+index}                                             
                                                >
                                                <TableBody>
                                                <TableRow
                                                    hover                                                    
                                                > 
                                                <TableCell style={{whiteSpace:'nowrap', height: 30, textOverflow: 'ellipsis'}}>
                                                <label>
                                                    <input type="radio" onChange={this.handleRadioChange(index)} value={row[col.alt]===null ? "" : row[col.alt]} id={col.name} name={col.name} 
                                                    checked={selectedCol[col.name]===index ? true : false}/> 
                                                    {row[col.name]}
                                                </label>                                                
                                                </TableCell>
                                                </TableRow>
                                                </TableBody>
                                                </Table> 
                                            );
                                        })
                                        }
                                    </Grid>
                                );
                            })
                            }
                            </Grid>  
                                                           

                        </Fragment>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={Global.handleSaveForm(this)} variant='contained' color="primary" size="small" style={inlineStyles.button} autoFocus>
                            Save
                    </Button>
                        <Button onClick={() => this.setEditDialogOpen(false)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                            Cancel
                    </Button>
                    </DialogActions>
                </Dialog>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}