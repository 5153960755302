import React from 'react';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  status: {
    danger: '#e91e63',
  },
});
const drawerWidth = 220;

export const inlineStyles = {
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    padding: 0,
    marginBottom: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.backgroundColor,
    color: theme.color,
  },
  container: {
    padding: 5,
  },
  content: {
    flexGrow: 1,
    padding: '20px 10px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    width: '100%',
    padding: '20px 10px',
  },
  paper: {
    //width: '100%',
    //marginBottom: theme.spacing(1),
  },
  topPaper: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  table750: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  editform: {
    padding: '10px 16px 20px',
  },

  iconButton: {
    flex: 1,
    padding: 4,
  },
  smallIcon: {
    fontSize: 16,
    color: 'inherit',
    marginRight: 5,
    marginBottom: 2,
  },

  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },

  buttonBlue: {
    whiteSpace: 'nowrap',
    color: '#ffffff',
    backgroundColor: '#507bca',
    '&:hover': {
      color: theme.hoverColor,
      backgroundColor: '#637282',
    }
  },
  actionButtonBlue: {
    padding: '3px 5px',
    fontSize: 11,
    textTransform: 'none',
    lineHeight: 'inherit',
    whiteSpace: 'nowrap',
    backgroundColor: '#507bca',
    '&:hover': {
      color: theme.hoverColor,
      backgroundColor: '#637282',
    }
  },
  buttonOrange: {
    whiteSpace: 'nowrap',
    color: 'white',
    backgroundColor: 'orange',
    '&:hover': {
      backgroundColor: '#dc920a',
    }
  },
  xsButton: {
    whiteSpace: 'nowrap',
    color: 'white',
    padding: '2px 6px',
    marginRight: 5,
    minWidth: 40,
    fontSize: 10,
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: '#dc920a',
    }
  },
  xsButtonGreen: {
    whiteSpace: 'nowrap',
    color: 'white',
    padding: '2px 6px',
    minWidth: 40,
    fontSize: 10,
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: '#0d650d',
    }
  },

  spacer: {
    flex: '1 1 auto',
  },

  textField: {
    margin: 5,
    minWidth: 185,
  },
  button: {
    margin: 3,
   

  },
  toolbarActionBox: {
    padding: 10,
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  footerBox: {
    padding: 10,
    textAlign: 'center',
  },
  listitem: {
    paddingTop: 8,
    paddingBottom: 8,
    borderTop: '1px solid #ecece7 !important',
  },
  sublistitem: {
    paddingTop: 0,
    paddingBottom: 3,
  },
  toolbarButtons: {
    marginLeft: "auto",
    marginRight: -12
  },
  hide: {
    display: 'none',
  },
  menuDivider: {
    display: 'inline-block', 
    padding: '0 5px', 
    color: '#7f7d7d'
  },
  nested: {
    paddingLeft: 27,
    paddingTop: 3,
    paddingBottom: 3,
    color: '#1a6db7',

  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  tdLabel: {
    fontWeight: 'bold',
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    height: 40,
    justifyContent: 'flex-end',
  },

  itemIcon: {
    minWidth: 35,
  },
  InputInput: {

  },
  linked: {
    textDecoration: 'none',
    color: 'white',
  },
  sectionHeaderSize: {
    fontSize: 14,
  },
  sectionHeaderBgColor: {
    backgroundColor: '#e5eff5',
  },
  sectionHeaderPads: {
    padding: '5px 10px',
  },
  select: {
    margin: 0,
    width: 'auto',
    minWidth: 140,
    backgroundColor: 'white',
  },
  select_edit: {
    backgroundColor: 'white',
  },
  toolbar: { minHeight: 50 },
  searchBox: {
    width: 'auto',
  },
  flexSearchBox: {
    display: 'flex',
    height: 30,
    marginRight: 18,
    backgroundColor: 'white',
    position: 'relative',
    width: 'auto',
  },
  flexSearchInput: {
    padding: '2px',
    flex: '1 1 auto',
    width: 200,
    '&:focus': {
      width: '300px !important',
    },
    borderBottom: '1px solid #777',
  },
  selectInSearchBox: {
    flex: '1 1 auto',
    margin: 0,
    maxWidth: 115,
    backgroundColor: '#e5edf5',
    borderBottom: 'none',

  },
  homeClearSearchIcon: {
    padding: 0,
    color: '#ccc',
    '&:hover': {
      color: '#e91e63',
      backgroundColor: 'transparent',
    },
    right: 5,
    top: 7,
    position: 'absolute',

  },
  searchIcon: { /* for search box on list paper toolbar */
    width: 34,
    padding: 0,
    color: '#ccc',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#918b85',
    },
    flex: '0 0 auto',
  },
  clearSearchIcon: {
    width: 24,
    padding: 0,
    color: '#ccc',
    '&:hover': {
      color: '#e91e63',
      backgroundColor: 'transparent',
    },
    flex: '0 0 auto',
  },
  smallestIcon: {
    fontSize: 16,
    marginTop: 0,
  },

};

export class Global {

  /* The arguments have to be passed on directly sometimes because setState is delayed */
  static loadList = (t, values) => {
    var user = JSON.parse(sessionStorage.getItem('userdata'));
    if (typeof (user.token) === 'undefined' && !user.token) return;
    var isDataLoading = values.hasOwnProperty('isDataLoading') ? values.isDataLoading : t.state.isDataLoading;
    if (isDataLoading) return;

    var order = (typeof values.order === 'undefined') ? t.state.order : values.order;
    var orderBy = (typeof values.orderBy === 'undefined') ? t.state.orderBy : values.orderBy;
    var rowsPerPage = (typeof values.rowsPerPage === 'undefined') ? t.state.rowsPerPage : values.rowsPerPage;
    var page = (typeof values.page === 'undefined') ? t.state.page : values.page;
    var searchFor = (typeof values.searchFor === 'undefined') ? t.state.searchFor : values.searchFor;
    
    if (t.state.hasOwnProperty('isDialogDataLoading') && !t.state.isDialogDataLoading) {
      t.setState({ isDialogDataLoading: true });
    }
    if (t.state.hasOwnProperty('isDataLoading') && !t.state.isDataLoading) {
      t.setState({ isDataLoading: true });
    }

    var formData = new FormData();
    if (t.state.hasOwnProperty('parent_id')) { /* needed by features for permissions */
      formData.append('parent_id',t.state.parent_id);
    } else if (t.props.hasOwnProperty('parentComponent') && t.props.parentComponent.state.hasOwnProperty('selectedID')) {
      formData.append('selectedID', t.props.parentComponent.state.selectedID);
    } 
    
    formData.append('token', user.token);
    formData.append('user_id', user.userdata.id);
    formData.append('order', order);
    formData.append('orderBy', orderBy);
    formData.append('rowsPerPage', rowsPerPage);
    formData.append('page', page);
    formData.append('searchFor', searchFor);
    if (typeof t.state.hasOwnProperty('startDate')) {
      formData.append('startDate', t.state.startDate);
    }
    if (typeof t.state.hasOwnProperty('endDate')) {
      formData.append('endDate', t.state.endDate);
    }
    if (typeof t.state.hasOwnProperty('selectedUser')) {
      formData.append('selectedUser', t.state.selectedUser);
    }
    if (typeof t.state.hasOwnProperty('selectedType')) {
      formData.append('selectedType', t.state.selectedType);
    }
    if (typeof t.state.hasOwnProperty('assignedTo')) {
      formData.append('assignedTo', t.state.assignedTo);
    }
    if (typeof t.state.hasOwnProperty('checkedBox')) {
      var checkedBox = JSON.stringify(t.state.checkedBox);      
      formData.append('checkedBox', checkedBox);
    }
    if (t.props.hasOwnProperty('parentComponent') && t.props.parentComponent.state.hasOwnProperty('selectedMenu')) {
      formData.append('reportCode', t.props.parentComponent.state.selectedMenu);
    }

    axios(
      {
        method: 'post',
        url: t.state.url,
        crossdomain: true,
        data: formData,
      })
      .then(res => res.data)
      .then(data => {
        if (data.hasOwnProperty('status')===false || data.status!=='success') {
          console.log('Error: '+data.message);
          t.setComponentState('notification', data.message);
        } else {          
          if (data.rows) {
            var rows = data.rows;
            t.setComponentState('notification', '');
            t.setComponentState('pageTotal', data.pages);
            t.setComponentState('count', data.total);
            t.setComponentState('rows', rows);
          } else {
            t.setComponentState('rows', []);
            t.setComponentState('notification', 'Error: Lost connection.');
          }
        }
      })
      .catch(e => {
        t.setComponentState('notification', `${e}`);
      })
      .finally(function () {
        if (t.state.hasOwnProperty('isDialogDataLoading')) {
          t.setState({ isDialogDataLoading: false });
        }
        t.setComponentState('isLoading', false);
        if (t.state.hasOwnProperty('isDataLoading')) {
          t.setState({ isDataLoading: false });          
        }
      });
  }
  /*
  * @param {obj}    t  caller object
  * @param {string} url - usually in .env file
  * @param {string} stateRows - state name that will get the return object (JSON) 
  * @param {string} sAction - action for the PHP API
  */
  static getDataRows = (t, url, stateRows, sAction, addlFields) => {
    var user = JSON.parse(sessionStorage.getItem('userdata'));
    if (typeof (user.token) === 'undefined' && !user.token) return;

    var formData = new FormData();
    formData.append('token', user.token);
    formData.append('user_id', user.userdata.id);
    if (typeof sAction === 'undefined') {
      formData.append('action', 'getAll');
    } else {
      formData.append('action', sAction);
    }
    if (t.props.hasOwnProperty('parentComponent')) {
      formData.append('selectedID', t.props.parentComponent.state.selectedID);
    } else { /* for App.js */
      if (stateRows === 'searchResult' && t.state.hasOwnProperty('searchTableVal')) {
        formData.append('searchFor', t.state.searchTableVal);
      }
    }
    if (typeof addlFields === 'object') {
      addlFields.forEach((v, i) => {
        formData.append(v.name, v.value);
      })
    }
    axios(
      {
        method: 'post',
        url: url,
        crossdomain: true,
        data: formData,
      })
      .then(res => res.data)
      .then(data => {
        if (data.hasOwnProperty('status')===false || data.status!=='success') {
          console.log('Error: '+data.message);
          if (data.message==='Invalid Token' && typeof t.logout==='function') {
            t.logout();
          }
          return [];
        } else {
          if (data.rows) {
            t.setState({ [stateRows]: data.rows });
          } else {
            console.log('error fetching the data');
            return [];
          }
        }
      })
      .catch(e => {
        console.log('Caught error: ', `${e}`);
        return [];
      })
      .finally(function () {
        if (t.state.hasOwnProperty('isDialogDataLoading')) {
          if (t.state.hasOwnProperty('reloadKey')) {          
            t.setState({ ...t.state, isDialogDataLoading: false, reloadKey: Math.random()  });
          } else {
            t.setState({ isDialogDataLoading: false });
          }
        }
        if (t.state.hasOwnProperty('isDataLoading')) {
          t.setState({ isDataLoading: false });
        }
      });
  }
  static setStateValue = (t, name, v) => {
    var schema = t.state.schema;
    var values = { ...t.state.schema };
    // eslint-disable-next-line
    schema.find((o, i) => {
      if (o.name === name) {
        values[i].value = v;
        return true;
      }
    });
    t.setState({ values });
  }
  
  static initializeStateValues = (t) => {
    var values = t.state.schema;
    values.forEach((row, key) => {
      values[key].error = '';
      values[key].value = '';
    })
    t.setState({ values });
  }
  static setStateValues = (t, data) => {
    var schema = t.state.schema;
    var values = { ...t.state.schema };
    var dataRows;
    if (data.rows.length>1) {
      let lastElem = data.rows.length - 1;
      dataRows = data.rows[lastElem];
    } else {
      dataRows = data.rows;
    }
    for (var key in dataRows) {
      if (dataRows.hasOwnProperty(key)) {
        // eslint-disable-next-line
        schema.find((o, i) => {
          if (o.name === key) {
            values[i].value = dataRows[key] === null ? '' : dataRows[key];
            if (o.name === 'accountname' && t.state.hasOwnProperty('accountName')) {
              t.setState({ accountName: o.value });
            }
            return true;
          }
        });
      }
    }
    t.setState({ values });
  }

  static getOneRow = (t, id) => {
    var user = JSON.parse(sessionStorage.getItem('userdata'));
    if (typeof (user.token) === 'undefined' && !user.token) return;

    if (t.state.hasOwnProperty('isDialogDataLoading')) {
      t.setState({ isDialogDataLoading: true });
    } else {
      t.setState({ isDataLoading: true });
    }
    t.setState({ id: id });
    t.setComponentState('notification', '');

    var formData = new FormData();
    formData.append('token', user.token);
    formData.append('user_id', user.userdata.id);
    formData.append('id', id);    
    formData.append('action', 'select');

    if (typeof t.state.hasOwnProperty('selected')) {
      formData.append('selected', t.state.selected);
    }
    
    if (t.props.hasOwnProperty('parentComponent')) {
      formData.append('selectedID', t.props.parentComponent.state.selectedID);
    }
    axios(
      {
        method: 'post',
        url: t.state.url,
        crossdomain: true,
        data: formData,
      })
      .then(res => res.data)
      .then(data => {
        if (data.hasOwnProperty('status')===false || data.status!=='success') {
          console.log('Error: '+data.message);
          t.setComponentState('notification', data.message);
        } else {
          if (data.rows) {
            if (t.state.hasOwnProperty('rows')) {
              t.setComponentState('rows', data.rows);
            }
            this.setStateValues(t, data);
          } else {
            t.setComponentState('notification', 'error fetching the data');
          }
        }
      })
      .catch(e => {
        t.setComponentState('notification', `Getting one row: ${e}`);
      })
      .finally(function () {
        if (t.state.hasOwnProperty('isDialogDataLoading')) {
          t.setState({ isDialogDataLoading: false });
        }
        if (t.state.hasOwnProperty('isDataLoading')) {
          t.setState({ isDataLoading: false });
        }
      });
  }

  static getSchema = (t, dataForEdit, nextAction) => {
    var user = JSON.parse(sessionStorage.getItem('userdata'));

    if (typeof (user.token) === 'undefined' && !user.token) return;

    t.setState({ isDataLoading: true });

    var formData = new FormData();
    formData.append('token', user.token);
    formData.append('user_id', user.userdata.id);
    formData.append('action', 'schema');
    if (t.state.hasOwnProperty('isEdit') && t.state.isEdit) {
      formData.append('isEdit', 1);
    } else {
      formData.append('isEdit', 0);
    }
    
    axios(
      {
        method: 'post',
        crossdomain: true,
        url: t.state.url,
        data: formData,
      })
      .then(res => res.data)
      .then(data => {
        
        if (data.hasOwnProperty('status')===false || data.status!=='success') {
          console.log('Error getschema: '+data.message);
          t.setComponentState('notification', 'error fetching the data');
        } else {          
          if (data.hasOwnProperty('schema')!==false ) {            
            t.setComponentState('schema', data.schema);
            if (typeof nextAction === 'function') {
              nextAction();
            }
          } else {
            t.setComponentState('notification', 'error fetching the data');

          }
        }
      })
      .catch(e => {
        t.setComponentState('notification', `${e}`);
      })
      .finally(function () {
        if (dataForEdit) {          
          if (t.state.id) {
            Global.getOneRow(t, t.state.id);
          } else {
            t.setState({ isDataLoading: false });
          }
        } else {
          Global.loadList(t, { isDataLoading: false });
        }
      });
  }

  static getSchemaForDialog = (t, tabname, nextAction) => {
    var user = JSON.parse(sessionStorage.getItem('userdata'));
    if (typeof (user.token) === 'undefined' && !user.token) return;

    const Url = t.props.parentComponent.state.API_HOST + process.env.REACT_APP_SCHEMAS_URL;

    t.setState({ isDialogDataLoading: true });

    var formData = new FormData();
    formData.append('token', user.token);
    formData.append('user_id', user.userdata.id);
    formData.append('schemaOf', tabname);
    axios(
      {
        method: 'post',
        url: Url,
        crossdomain: true,
        data: formData,
      })
      .then(res => res.data)
      .then(data => {
        if (data.hasOwnProperty('schema')!==false ) {
          t.setComponentState('editFormSchema', data.schema);
          if (typeof nextAction === 'function') {
            nextAction();
          }        
        }
      })
      .catch(e => {
        console.log('notification', `${e}`);
      })
      .finally(function () {
        t.setState({ isDialogDataLoading: false });
      });
  }

//NT created for plan of action and notes  
static handlePofaNotesForm = (t,sAction) => (e) => {    
  e.preventDefault();
  var errorFound = this.validate(t);
  if (errorFound) {
    console.log('error',errorFound);
    t.setComponentState('notification', 'Fill up required fields');
    return;
  }
  t.setComponentState('notification', '');
  let user = JSON.parse(sessionStorage.getItem('userdata'));
  if (typeof (user.token) === 'undefined' && !user.token) return;

  if (t.state.hasOwnProperty('isDialogDataLoading')) {
    t.setComponentState('isDialogDataLoading', true);
    if (t.state.hasOwnProperty('calendar')) {
      t.setComponentState('isDataLoading', true);
    }
  } else {
    t.setComponentState('isDataLoading', true);
  }
  
  var formData = new FormData();
  if (t.props.hasOwnProperty('parentComponent')) {
    formData.append('selectedID', t.props.parentComponent.state.selectedID);
  }
  if (t.state.hasOwnProperty('id')) {
    formData.append('id', t.state.id);
  }
  if (t.state.hasOwnProperty('selected')) {
    formData.append('selected', t.state.selected);
  }
  
  formData.append('user_id', user.userdata.id);
  formData.append('token', user.token);
  formData.append('action', 'savePofANotes');

  var formValues = t.state.schema;
  var values = { ...t.state.schema };
  formValues.forEach((row, key) => {
    formData.append(row.name, row.value);
    values[key].error = '';
    t.setState({ values });
  })

  var result = '';
  var thisClass = this;
  axios(
    {
      method: 'post',
      url: t.state.url,
      crossdomain: true,
      data: formData,
    })
    .then(res => res.data)
    .then(data => {        
      if (data.hasOwnProperty('status')===false || data.status!=='success') {
        console.log('Error saving: '+data.message);
        t.setComponentState('notification', data.message);
      } else {
        if (data.status && data.status === 'success') {
          result = data.status;            
          if (t.state.hasOwnProperty('redirectTo')) {                            
            result = 'redirected';
            var id = data.id ? data.id : 0;
            let oId = { idFor: 'selectedID', value: id };
            t.props.parentComponent.selectMenu(t.state.redirectTo, oId, { msg: 'Record successfully saved', type: 'success' });
          } else {
            t.openSnackBar('Record successfully saved', 'success', true);              
            if (t.state.hasOwnProperty('editDialogOpen')) {                
              t.setComponentState('editDialogOpen', false);
            }              
            if (t.state.hasOwnProperty('dialogAddEditOpen')) {                
              t.setComponentState('dialogAddEditOpen', false);
            }                                     
          }

        } else {
          t.setComponentState('notification', 'Error saving: Lost connection.');
        }
      }
    })
    .catch(e => {
      t.setComponentState('notification', `${e}`);
    })
    .finally(function () {
      if (result === 'redirected') return;
      if (typeof sAction === 'function') {          
        sAction();          
      } else if (result === 'success' && t.state.hasOwnProperty('isDialogDataLoading')) {          
        var values = {
          isDataLoading: false,
          page: 0
        };
        thisClass.loadList(t, values);
      }
      if (t.state.hasOwnProperty('isDialogDataLoading')) {
        t.setComponentState('isDialogDataLoading', false);
      }
      t.setComponentState('isDataLoading', false);
      if (t.state.hasOwnProperty('addNew')) { /* example in Clients/Expenses.js */
        t.setComponentState('addNew', false);
        thisClass.loadList(t, {});
        thisClass.initializeStateValues(t);
      }
      if (t.state.hasOwnProperty('editPNremarks')) { /* example in Clients/LoanDetails.js */
        t.setComponentState('editPNremarks', false);
      }
    });
}




  static handleSaveForm = (t,sAction) => (e) => {    
    e.preventDefault();
    var errorFound = this.validate(t);
    if (errorFound) {
      console.log('error',errorFound);
      t.setComponentState('notification', 'Fill up required fields');
      return;
    }
    t.setComponentState('notification', '');
    let user = JSON.parse(sessionStorage.getItem('userdata'));
    if (typeof (user.token) === 'undefined' && !user.token) return;

    if (t.state.hasOwnProperty('isDialogDataLoading')) {
      t.setComponentState('isDialogDataLoading', true);
      if (t.state.hasOwnProperty('calendar')) {
        t.setComponentState('isDataLoading', true);
      }
    } else {
      t.setComponentState('isDataLoading', true);
    }
    
    var formData = new FormData();
    if (t.props.hasOwnProperty('parentComponent')) {
      formData.append('selectedID', t.props.parentComponent.state.selectedID);
    }
    if (t.state.hasOwnProperty('id')) {
      formData.append('id', t.state.id);
    }
    if (t.state.hasOwnProperty('selected')) {
      formData.append('selected', t.state.selected);
    }
    
    formData.append('user_id', user.userdata.id);
    formData.append('token', user.token);
    formData.append('action', 'save');

    var formValues = t.state.schema;
    var values = { ...t.state.schema };
    formValues.forEach((row, key) => {
      formData.append(row.name, row.value);
      values[key].error = '';
      t.setState({ values });
    })

    var result = '';
    var thisClass = this;
    axios(
      {
        method: 'post',
        url: t.state.url,
        crossdomain: true,
        data: formData,
      })
      .then(res => res.data)
      .then(data => {        
        if (data.hasOwnProperty('status')===false || data.status!=='success') {
          console.log('Error saving: '+data.message);
          t.setComponentState('notification', data.message);
        } else {
          if (data.status && data.status === 'success') {
            result = data.status;            
            if (t.state.hasOwnProperty('redirectTo')) {                            
              result = 'redirected';
              var id = data.id ? data.id : 0;
              let oId = { idFor: 'selectedID', value: id };
              t.props.parentComponent.selectMenu(t.state.redirectTo, oId, { msg: 'Record successfully saved', type: 'success' });
            } else {
              t.openSnackBar('Record successfully saved', 'success', true);              
              if (t.state.hasOwnProperty('editDialogOpen')) {                
                t.setComponentState('editDialogOpen', false);
              }              
              if (t.state.hasOwnProperty('dialogAddEditOpen')) {                
                t.setComponentState('dialogAddEditOpen', false);
              }                                     
            }

          } else {
            t.setComponentState('notification', 'Error saving: Lost connection.');
          }
        }
      })
      .catch(e => {
        t.setComponentState('notification', `${e}`);
      })
      .finally(function () {
        if (result === 'redirected') return;
        if (typeof sAction === 'function') {          
          sAction();          
        } else if (result === 'success' && t.state.hasOwnProperty('isDialogDataLoading')) {          
          var values = {
            isDataLoading: false,
            page: 0
          };
          thisClass.loadList(t, values);
        }
        if (t.state.hasOwnProperty('isDialogDataLoading')) {
          t.setComponentState('isDialogDataLoading', false);
        }
        t.setComponentState('isDataLoading', false);
        if (t.state.hasOwnProperty('addNew')) { /* example in Clients/Expenses.js */
          t.setComponentState('addNew', false);
          thisClass.loadList(t, {});
          thisClass.initializeStateValues(t);
        }
        if (t.state.hasOwnProperty('editPNremarks')) { /* example in Clients/LoanDetails.js */
          t.setComponentState('editPNremarks', false);
        }
      });
  }

  static SaveDialogFormData = (t, url, values, tabname, callbackAction, resultMsg) => {    
    var errorFound = false;
    values.forEach((row, key) => {
      if (row.required && !row.value) {
        errorFound = true;                
      }
    })
    if (errorFound) {      
      t.setComponentState('dialogNotification', 'Fill up required fields');
      return;
    }
    t.setComponentState('dialogNotification', '');

    let user = JSON.parse(sessionStorage.getItem('userdata'));
    if (typeof (user.token) === 'undefined' && !user.token) return;

    if (t.state.hasOwnProperty('isDialogDataLoading')) {
      t.setComponentState('isDialogDataLoading', true);
    }

    var formData = new FormData();
    formData.append('user_id', user.userdata.id);
    formData.append('token', user.token);
    formData.append('tab', tabname);
    values.forEach((row, key) => {
      formData.append(row.name, row.value);
    })
    var result = '';
    axios(
      {
        method: 'post',
        url: url,
        crossdomain: true,
        data: formData,
      })
      .then(res => res.data)
      .then(data => {                
        if (data.hasOwnProperty('status')===false || data.status!=='success') {
          console.log('Error savefromdialog: '+data.message);
          t.setComponentState('dialogNotification', data.message);
        } else {
          if (data.status && data.status === 'success') {
            result = data.status;       
            if (data.hasOwnProperty('rows') && tabname) {  
              t.setListData(tabname, data.rows);              
            }            
            if (typeof callbackAction==='function') {              
              let id = data.hasOwnProperty('id') ? data.id : 0;         
              callbackAction(id);
            }            
            if (typeof resultMsg!=='undefined') {
              t.openSnackBar(resultMsg, 'success', true);
            } else {
              t.openSnackBar('Record successfully saved', 'success', true);
            }
          } else {
            t.setComponentState('dialogNotification', 'Error: Lost connection.');
          }
        }
      })
      .catch(e => {
        t.setComponentState('dialogNotification', `${e}`);
      })
      .finally(function () {
        if (result === 'success') {

          if (t.state.hasOwnProperty('isDialogDataLoading')) {
            t.setComponentState('isDialogDataLoading', false);
          }
          if (t.state.hasOwnProperty('dialogAddEditOpen')) {
            t.setComponentState('dialogAddEditOpen', false);
          }
        }

      });
  }

  static getList = (t, url, id, tabname) => {

    let user = JSON.parse(sessionStorage.getItem('userdata'));
    if (typeof (user.token) === 'undefined' && !user.token) return;

    var formData = new FormData();
    formData.append('user_id', user.userdata.id);
    formData.append('token', user.token);
    formData.append('tab', tabname);
    formData.append('id', id);
    
    axios(
      {
        method: 'post',
        url: url,
        crossdomain: true,
        data: formData,
      })
      .then(res => res.data)
      .then(data => {
        if (data.hasOwnProperty('status')===false || data.status!=='success') {
          console.log('Error getlist: '+data.message);
          t.setComponentState('dialogNotification', data.message);
        } else {
          if (data.status && data.status === 'success') {            
            if (data.hasOwnProperty('rows')) {  
              t.setListData(tabname, data.rows);
            }                        
          }
        }
      })
      .catch(e => {
        console.log('Error:', `${e}`);
      });
  }
  static handleDialogCancel = (t) => {
    if (t.state.hasOwnProperty('editDialogOpen')) {
      t.setComponentState('editDialogOpen', false);
    }
    this.initializeEditDialogValues(t);
    this.loadList(t, {});
  }

  static initializeEditDialogValues = (t) => {
    /* initialize the values and error messages after closing the edit dialog for next edit */
    var formValues = t.state.schema;
    var values = { ...t.state.schema };
    formValues.forEach((row, key) => {
      values[key].error = '';
      values[key].value = '';
    })
    t.setState({ values });
  }

  static deleteRow = (t) => {
    let user = JSON.parse(sessionStorage.getItem('userdata'));
    if (typeof (user.token) === 'undefined' && !user.token) return;

    if (t.state.hasOwnProperty('isDialogDataLoading')) {
      if (t.state.isDialogDataLoading) return;
      t.setComponentState('isDialogDataLoading', true);
    } 
    if (t.state.hasOwnProperty('isDataLoading')) {
      t.setComponentState('isDataLoading', true);
    }
    t.setComponentState('notification', '');

    var formData = new FormData();
    formData.append('id', t.state.id);
    formData.append('user_id', user.userdata.id);
    formData.append('token', user.token);
    formData.append('action', 'delete');

    axios(
      {
        method: 'post',
        url: t.state.url,
        crossdomain: true,
        data: formData,
      })
      .then(res => res.data)
      .then(data => {
        if (data.hasOwnProperty('status')===false || data.status!=='success') {
          console.log('Error deleterow: '+data);          
          t.setComponentState('notification', data.message);
        } else {
          if (data.status && data.status === 'success') {
            t.openSnackBar('Record successfully deleted', 'success', true);
          } else {
            t.setComponentState('notification', 'Error: Lost connection.');
          }
        }
      })
      .catch(e => {
        t.setComponentState('notification', `${e}`);
      })
      .finally(function () {
        if (t.state.hasOwnProperty('deleteDialogOpen')) {          
          t.setDeleteDialogOpen(false); /*loadList gets triggered here */
        } 
        if (t.state.hasOwnProperty('isDataLoading')) {
          t.setComponentState('isDataLoading', false);
        }
        if (t.state.hasOwnProperty('isDialogDataLoading')) {
          t.setComponentState('isDialogDataLoading', false);
        }
        
      });
  }

  static deleteRowByTab = (t, id) => {
    let user = JSON.parse(sessionStorage.getItem('userdata'));
    if (typeof (user.token) === 'undefined' && !user.token) return;

    const deleteUrl = t.props.parentComponent.state.API_HOST + process.env.REACT_APP_DELETE_URL;

    if (t.state.hasOwnProperty('isDialogDataLoading')) {
      if (t.state.isDialogDataLoading) return;
      t.setComponentState('isDialogDataLoading', true);
    } else {
      t.setComponentState('isDataLoading', true);
    }
    t.setComponentState('notification', '');

    var formData = new FormData();
    formData.append('token', user.token);
    formData.append('user_id', user.userdata.id);
    formData.append('id', id);
    if (t.props.parentComponent.state.hasOwnProperty('loanid')) {
      formData.append('loanid', t.props.parentComponent.state.loanid);
    }
    formData.append('tab', t.state.selectedTable);

    axios(
      {
        method: 'post',
        url: deleteUrl,
        crossdomain: true,
        data: formData,
      })
      .then(res => res.data)
      .then(data => {
        if (data.hasOwnProperty('status')===false || data.status!=='success') {
          console.log('Error deleterowbytab: '+data.message);
          t.setComponentState('notification', data.message);
        } else {
          if (data.status && data.status === 'success') {
            if (data.tab && data.rows) {              
              t.setListData(data.tab, data.rows);
            }
            t.openSnackBar('Record successfully deleted', 'success', true);
          } else {
            t.setComponentState('notification', 'Error occurred. Please report this to the system administrator');
          }
        }
      })
      .catch(e => {
        t.setComponentState('notification', `${e}`);
      })
      .finally(function () {
        if (t.state.hasOwnProperty('deleteDialogOpen')) {
          t.setDeleteDialogOpen(false); /*loadList gets triggered here */
        }
        if (t.state.hasOwnProperty('isDialogDataLoading')) {
          t.setComponentState('isDialogDataLoading', false);
        }
        if (t.state.hasOwnProperty('isDataLoading')) {
          t.setComponentState('isDataLoading', false);
        }
      });
  }

  static showPdf = (t, sURL, pageSize, orientation) => {
    var user = JSON.parse(sessionStorage.getItem('userdata'));
    if (typeof (user.token) === 'undefined' && !user.token) return;

    if (t.state.isShowingPdf) return;

    t.setState({ isShowingPdf: true });
    var formData = new FormData();
    formData.append('token', user.token);
    formData.append('user_id', user.userdata.id);
    formData.append('id', t.props.parentComponent.state.loanid);
    formData.append('selectedID', t.props.parentComponent.state.selectedID);
    formData.append('pageSize', pageSize);
    formData.append('orientation', orientation);

    axios({
      method: 'POST',
      url: sURL,
      crossdomain: true,
      data: formData,
      responseType: 'blob'
    })
      .then(response => {
        //Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' });
        //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        t.setState({ isShowingPdf: false });
      })
      .catch(error => {
        t.setState({ isShowingPdf: false });
        console.log(error);
      });
  }
  static startSearch = (t) => {
    t.setComponentState('page', 0);
    this.loadList(t, { page: 0 });
  }

  static clearSearch = (t) => {
    t.setState({ searchFor: '' });
    t.setComponentState('page', 0);
    t.setComponentState('showClearSearchBtn', false);
    var values = {
      searchFor: '',
      page: 0,
    };
    this.loadList(t, values);
  }
  static validate = (t) => {
    var errorFound = false;
    var formValues = t.state.schema;
    var values = { ...t.state.schema };
    formValues.forEach((row, key) => {
      if (row.required && !row.value && row.name !== 'email') {
        values[key].error = row.label + ' is required';
        t.setState({ values });
        errorFound = true;
      } else if (row.required && row.name === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(row.value)) {
        values[key].error = 'Invalid email';
        t.setState({ values });
        errorFound = true;
      }
    })
    return errorFound;
  }
  static checkObj = (s) => {
    s = s.split('.')
    var obj = window[s.shift()];
    while (obj && s.length) obj = obj[s.shift()];
    return obj;
  }
  static isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

}

export function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}
