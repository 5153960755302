import React, { Fragment, Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import NotFound from './contents/NotFound';
import SignIn from './components/Signin';
import Users from './contents/settings/Users';
import { loadCSS } from 'fg-loadcss';
import Edituser from './contents/settings/Edituser';
import { Global } from './helpers/global';
import Roles from './contents/settings/Roles';
import Clients from './contents/Contacts/Contacts';
import "./css/styles.css";

import EditRole from './contents/settings/Editrole';
import { TopMenu } from './layout/TopMenu';
import Offices from './contents/Offices/Offices';
import EditContact from './contents/Contacts/EditContact';
import ContactDetails from './contents/Contacts/ContactDetails';
import OfficeDetails from './contents/Offices/OfficeDetails';
import EditOffice from './contents/Offices/EditOffice';
import MyProfile from './contents/Profile/profile';
import Events from './contents/Events/Events';
import Preferences from './contents/settings/Preferences';
import QuoteValueReport from './contents/Reports/QuoteValueReport';
import CRMCalendar from './contents/Calendar/CRMCalendar';

import Activities from './contents/Reports/Activities';
import TobeAssigned from './contents/Reports/TobeAssigned';
import NoAccounts from './contents/Reports/NoAccounts';
import MergeContacts from './contents/Contacts/MergeContacts';
import UnlinkedEmails from './contents/Reports/UnlinkedEmails';
/*
To do:
- fill in "Assigned to" with the current user's name when applicable
- Preferences: stages, agent types, Reality versions, Reality status
*/

require('dotenv').config();

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      label: { // after the dash
        position: 'relative',
        textTransform: 'initial',
      },
    },
    MuiIconButton: {
      colorInherit: {
        color: 'inherit',
      },
    },
    MuiIcon: {
      root: {
        fontSize: 16,
      }
    },
    MuiSelect: {
      root: {
        margin: 0,
        padding: 0,
      },
      selectMenu: {
        width: '100%',
        minHeight: 16,
        margin: 1,
        backgroundColor: 'inherit !important',
      }
    },
    MuiTab: {
      root: {
        padding: '3 12',
        '&:hover': {
          color: '#3c8dd4',
        }
      },
      labelIcon: {
        padding: 0,
        paddingTop: 0,
      }
    },
    MuiTableCell: {
      root: {
        //NT changed font size from 85% to 100%
        fontSize: '100% !important',
        fontFamily: 'Arial',
      },
      sizeSmall: {
        padding: 5,
      }
    },
    MuiTablePagination: {
      input: {
        flexShrink: 1,
        border: '1px solid #d2e6d5',
        width: 90,
        padding: 2,
      },
      select: {
        paddingRight: 24,
        textAlignLast: 'left !important',
      },
      selectRoot: {
        marginRight: 16,
        width: 45,
      }
    },
    MuiOutlinedInput: {
      input: {
        //padding: '2px 5px !important',
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#a6d3fb'
      }
    },
    MuiToolbar: {
      root: {
        //backgroundColor: '#a6d3fb'
      }
    },
    MuiTypography: {
      body1: {
     
        fontSize: 13,
      },
      body2: {
       
        fontSize: 11,
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: 32,
        lineHeight: 0,
        paddingBottom:10,
        paddingTop:10,
      }
    },
    MuiTextField: {
      root: {
        width: '100%',
      }
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
        color: '#1a6db7',
      }
    },
    MuiDialogContentText: {
      root: {
        marginBottom: 10,
        fontSize: 10,
      }
    },
    MuiInputBase: {
      root: {
        width: '100%',
        padding: '5px',
        fontSize: 14,
      },
      input: {
        width: '100%',
        padding: '2px 5px',
      },

    },
  },
  palette: {
    primary: {
      main: '#3c8dd4',
      light: '#ccc',
      dark: '#6ab4f5'
    },
    secondary: {
      main: '#d32f2f'
    },
    action: {
      main: '#6495ed',
      light: '#ccc',
      dark: '#637282',
    }
  },

});

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackBarMsg: { msg: '', type: '' },
      anchorEl: null,
      selectedMenu: '/',
      collapsed_cmc: false,
      collapsed_settings: false,
      updateThePage: true,
      isDataLoading: false,
      loadedCount: 0, /* for chart data in dashboard */
      userdata: {},
      loanProcessingCount: { rows: '...' },
      monthlyRecap: { loaded: false, rows: [], count: 0 },
      menuId: 'primary-search-account-menu',
      permissions: {
        Calendar: '',
        Contacts: '',
        Offices: '',
        Archived: '',
        Reports: '',
        QuoteValueReport: '',
        Preferences: '',
        Users: '',
        Roles: ''
      },
      notification: '',
      searchTable: 'Contact',
      searchTableVal: '',
      searchResult: [],
      searchStarted: false,
      crmUserId: 0,
      selectedID: 0,
    }

 

  }
  collapseMenu_loans = (v) => {
    this.setState({ collapsed_loans: v });
  }

  collapseMenu_settings = (v) => {
    this.setState({ collapsed_settings: v });
  }
 
  componentDidUpdate() {
    if (this.state.updateThePage && !this.state.searchTableVal && (!this.state.selectedMenu || this.state.selectedMenu === '/')) {
      this.setState({ updateThePage: false });
    }

  }

  componentDidMount() {

    //NT prevent back button action
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
    //End
    
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    if (sessionStorage.getItem('userdata')) {
      var vUserdata = JSON.parse(sessionStorage.getItem('userdata'));

      var permissions = this.state.permissions;

      var checkedPermissions;

      for (var featureId in permissions) {
        checkedPermissions = this.checkPermission(featureId);
        if (checkedPermissions) {
          permissions[featureId] = checkedPermissions;
        }
      }
      var oStates = this.state;
      oStates.userdata = vUserdata;
      oStates.permissions = permissions;
      oStates.crmUserId = vUserdata.userdata.id;
      this.setState({ oStates });
    }
    
  }

  selectMenu = (sMenuitem, Id, oMsg) => {
    var states = this.state;
    if (typeof Id === 'object') {
      if (Id.hasOwnProperty('idFor')) {
        states[Id.idFor] = Id.value;
      }
    }
    if (typeof oMsg !== 'undefined') {
      states.snackBarMsg = oMsg;
    } else {
      states.snackBarMsg = { msg: '', type: '' };
    }
    states.selectedMenu = sMenuitem;
    this.setState({ states });
  }

  logout = () => {
    this.handleMenuClose();
    var oStates = this.state;
    oStates.userdata = {};
    oStates.anchorEl = null;
    this.setState({ oStates });
    sessionStorage.clear();
  }

  handleCollapseMenu = (menuId) => {
    switch (menuId) {
      case 'loans':
        if (this.state.collapsed_cmc) this.collapseMenu_cmc(false);
        if (this.state.collapsed_gl) this.collapseMenu_gl(false);
        if (this.state.collapsed_trans) this.collapseMenu_trans(false);
        if (this.state.collapsed_loanreports) this.collapseMenu_loanreports(false);
        if (this.state.collapsed_actgrep) this.collapseMenu_actgrep(false);
        if (this.state.collapsed_settings) this.collapseMenu_settings(false);

        this.collapseMenu_loans(!this.state.collapsed_loans);
        break;
      case 'settings':
        if (this.state.collapsed_loans) this.collapseMenu_loans(false);
        if (this.state.collapsed_cmc) this.collapseMenu_cmc(false);
        if (this.state.collapsed_trans) this.collapseMenu_trans(false);
        if (this.state.collapsed_gl) this.collapseMenu_gl(false);
        if (this.state.collapsed_loanreports) this.collapseMenu_loanreports(false);
        if (this.state.collapsed_actgrep) this.collapseMenu_actgrep(false);

        this.collapseMenu_settings(!this.state.collapsed_settings);
        break;
      default:
        if (this.state.collapsed_loans) this.collapseMenu_loans(false);
        if (this.state.collapsed_cmc) this.collapseMenu_cmc(false);
        if (this.state.collapsed_trans) this.collapseMenu_trans(false);
        if (this.state.collapsed_gl) this.collapseMenu_gl(false);
        if (this.state.collapsed_loanreports) this.collapseMenu_loanreports(false);
        if (this.state.collapsed_actgrep) this.collapseMenu_actgrep(false);
        if (this.state.collapsed_settings) this.collapseMenu_settings(false);
    }
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  }
  setSearchTable = (v) => {
    this.setState({ searchTable: v });

  }
  clearSearch = () => {
    var oStates = this.state;
    oStates.searchTableVal = '';
    oStates.searchStarted = false;
    this.setState({ oStates });
  }
  setSearchVal = (v) => {
    this.setState({ searchTableVal: v });
  }
  startSearch = () => {
    var oStates = this.state;
    oStates.searchResult = '';
    oStates.selectedMenu = '';
    oStates.isDataLoading = true;
    oStates.searchStarted = true;
    this.setState({ oStates });
    if (this.state.searchTable === 'Contact') {
      Global.getDataRows(this, process.env.REACT_APP_API_HOST + 'contacts', 'searchResult', 'getAll');
    } else {
      Global.getDataRows(this, process.env.REACT_APP_API_HOST + 'offices', 'searchResult', 'getAll');
    }
  }
  loadingCircular = () => {
    return (
      <Fragment>
        <div style={{ 'padding': 50, 'textAlign': 'center' }}>
          <CircularProgress />
        </div>
      </Fragment>
    )
  }

  incrementLoadedCount = () => {
    var oStates = this.state;
    oStates.loadedCount = oStates.loadedCount + 1;
    this.setState({ oStates });
  }
  checkPermission = (featureId) => {
    var userdata = JSON.parse(sessionStorage.getItem('userdata'));
    if (userdata.hasOwnProperty('permissions') && userdata.permissions.hasOwnProperty(featureId)) {
      return userdata.permissions[featureId];
    } else {
      return false;
    }
  }

  render() {


    var userdata = this.state.userdata;
    var content = '';

    var permissions = this.state.permissions;

    // case 'welcome':
    //   content = <Welcome Global={Global} parentComponent={this} inlineStyles={inlineStyles} />;
    //   break;
    switch (this.state.selectedMenu) {
      case '':
      case '/':
      case 'contacts':
        if (permissions.Contacts.indexOf('f') !== -1 || permissions.Contacts.indexOf('v') !== -1) {
          content = <Clients parentComponent={this} theme={theme} />;
        }
        break;

      case 'calendar':
        if (permissions.Calendar.indexOf('f') !== -1 || permissions.Calendar.indexOf('v') !== -1) {
          content = <CRMCalendar parentComponent={this} theme={theme} />
        }
        break;

      case 'contact.details':
        if (permissions.Contacts.indexOf('f') !== -1 || permissions.Contacts.indexOf('v') !== -1) {
          content = <ContactDetails parentComponent={this} theme={theme} />;
        }
        break;
      case 'contact.edit':
        if (permissions.Contacts.indexOf('f') !== -1 || permissions.Contacts.indexOf('u') !== -1) {
          content = <EditContact parentComponent={this} theme={theme} />;
        }
        break;
      case 'contact.merge':
        if (permissions.Contacts.indexOf('f') !== -1 || permissions.Contacts.indexOf('u') !== -1) {
          content = <MergeContacts parentComponent={this} theme={theme} />;
        }
        break;
      case 'offices':
        if (permissions.Offices.indexOf('f') !== -1 || permissions.Offices.indexOf('v') !== -1) {
          content = <Offices parentComponent={this} theme={theme} />;
        }
        break;
      case 'office.details':
        if (permissions.Offices.indexOf('f') !== -1 || permissions.Offices.indexOf('v') !== -1) {
          content = <OfficeDetails parentComponent={this} theme={theme} />;
        }
        break;
      case 'office.edit':
        if (permissions.Offices.indexOf('f') !== -1 || permissions.Offices.indexOf('u') !== -1) {
          content = <EditOffice parentComponent={this} theme={theme} />;
        }
        break;
      case 'preferences':
        if (permissions.Preferences.indexOf('f') !== -1 || permissions.Preferences.indexOf('v') !== -1) {
          content = <Preferences parentComponent={this} theme={theme} />;
        }
        break;
      case 'report.quotevalue':
        if (permissions.QuoteValueReport.indexOf('f') !== -1 || permissions.QuoteValueReport.indexOf('v') !== -1) {
          content = <QuoteValueReport parentComponent={this} theme={theme} />;
        }
        break;
      case 'report.unlinked':
          if (permissions.Reports.indexOf('f') !== -1 || permissions.Reports.indexOf('v') !== -1) {
            content = <UnlinkedEmails parentComponent={this} theme={theme} />;
          }
          break;
      case 'report.todayEvents':
      case 'report.yesterdayEvents':
      case 'report.thisWeekEvents':
      case 'report.lastWeekEvents':
      case 'report.thisMonthEvents':
      case 'report.todayEmailsIn':
      case 'report.yesterdayEmailsIn':
      case 'report.todayEmailsOut':
      case 'report.yesterdayEmailsOut':
          if (permissions.Reports.indexOf('f') !== -1 || permissions.Reports.indexOf('v') !== -1) {
            content = <Activities parentComponent={this} reportCode={this.state.selectedMenu} theme={theme} />;
          }
          break;
      case 'report.noAccounts':
        if (permissions.Reports.indexOf('f') !== -1 || permissions.Reports.indexOf('v') !== -1) {
          content = <NoAccounts parentComponent={this} theme={theme} />;
        }
        break;
      case 'report.contactsByAccounts':
        if (permissions.Reports.indexOf('f') !== -1 || permissions.Reports.indexOf('v') !== -1) {
          content = <Activities parentComponent={this} day={'contactsByAccounts'} theme={theme} />;
        }
        break;
      case 'report.tobeAssigned':
        if (permissions.Reports.indexOf('f') !== -1 || permissions.Reports.indexOf('v') !== -1) {
          content = <TobeAssigned parentComponent={this} theme={theme} />;
        }
        break;      
      case 'settings.users':
        if (permissions.Users.indexOf('f') !== -1 || permissions.Users.indexOf('v') !== -1) {
          content = <Users parentComponent={this} theme={theme} />;
        }
        break;
      case 'settings.roles':
        if (permissions.Roles.indexOf('f') !== -1 || permissions.Roles.indexOf('v') !== -1) {
          content = <Roles parentComponent={this} theme={theme} />;
        }
        break;
      case 'settings.edituser':
        if (permissions.Users.indexOf('f') !== -1 || permissions.Users.indexOf('u') !== -1) {
          content = <Edituser parentComponent={this} theme={theme} />;
        }
        break;
      case 'settings.editrole':
        if (permissions.Roles.indexOf('f') !== -1 || permissions.Roles.indexOf('u') !== -1) {
          content = <EditRole parentComponent={this} theme={theme} />;
        }
        break;
      case 'events':
        if (permissions.Contacts.indexOf('f') !== -1 || permissions.Contacts.indexOf('u') !== -1) {
          content = <Events parentComponent={this} theme={theme} />;
        }
        break;
      case 'user.profile':
        content = <MyProfile parentComponent={this} theme={theme} />;
        break;
      default:

    }
    if (!content) {
      content = <NotFound />;
    }
    
    return (
      <Box className={"maincontainer"}>
        <MuiThemeProvider theme={theme}>
          {userdata.userdata ?
            <Fragment>
              <TopMenu parentComponent={this} theme={theme} content={content} />
              <Typography style={{ position: 'fixed', zIndex: 100, borderTop: '1px solid #dbe4c1', bottom: 0, backgroundColor: '#f0f3e8', padding: 5, textAlign: 'center', width: '100%', fontSize: 12 }}>
                {process.env.REACT_APP_COMPANY} - &copy; {(new Date().getFullYear())}
              </Typography>
            </Fragment>
            :
            <Fragment>
              <SignIn />
            </Fragment>
          }

        </MuiThemeProvider>
      </Box>
    );
  }
}