import React from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => { }, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth      
      variant="outlined"      
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  containerBelow: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
  suggestionsContainerOpenBelow: {
    padding:0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
}));

export default function TextAutoSearch(props) {
  const classes = useStyles();
  
  const [state, setState] = React.useState({
    selectedVal: '',
    initState: true,
  });
  // eslint-disable-next-line
  React.useEffect(() => {
    if (state.initState && props.textValue) {      
      setState({
        ...state,
        selectedVal: `${props.textValue}`,
        initState: false,
      });
    }
  });
  const [stateSuggestions, setSuggestions] = React.useState([]);

  const getSuggestions = (value) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 2) return false;

    var user = JSON.parse(sessionStorage.getItem('userdata'));
    if (typeof (user.token) === 'undefined' && !user.token) return false;

    const Url = process.env.REACT_APP_API_HOST + 'search';

    var formData = new FormData();
    formData.append('token', user.token);
    formData.append('user_id', user.userdata.id);
    formData.append('tab', props.tabname);
    formData.append('search', value);
    axios(
      {
        method: 'post',
        url: Url,
        data: formData,
      })
      .then(res => res.data)
      .then(data => {
        setSuggestions(data.rows);
      })
      .catch(e => {
        console.log('notification', `${e}`);
      });
   
  }
  const handleSuggestionsFetchRequested = ({ value }) => {
    // setSuggestions(getSuggestions(value,props));
    getSuggestions(value);
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = name => (event, { newValue }) => {    
    setState({
      ...state,
      selectedVal: newValue
    });
  };
  const handleGetSuggestionValue = (suggestion) => {
    if (props.hasOwnProperty('updateRowObj')) {      
      props.updateRowObj(props.tabname,suggestion);
    } else if (props.t.hasOwnProperty('selectedIdFromDialog')) {
      props.t.setState({
        ...state,
        selectedIdFromDialog: suggestion.id
      });
    }

    return suggestion.label;
  }
  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue: handleGetSuggestionValue,
    renderSuggestion,
  };

  return (
    <div className={props.hasOwnProperty('popupBelow') ? 'AutoSearchOnTop' : classes.root}>      
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          id: 'react-autosuggest',          
          placeholder: `search ${props.tabname}...`,
          value: state.selectedVal,
          onChange: handleChange(),
          margin: props.hasOwnProperty('margin') ? props.margin : '',
        }}
        theme={{
          container: props.hasOwnProperty('popupBelow') ? classes.containerBelow: classes.container,
          suggestionsContainerOpen: props.hasOwnProperty('popupBelow') ? classes.suggestionsContainerOpenBelow : classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />

    </div>
  );
}